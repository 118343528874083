import Picture from './picture';
import { assert } from 'chai';
import { v4 as uuidv4 } from 'uuid';

interface RawPictureDTO {
  id: string;
  userId: string;
  tourId?: string;
  url: string;
  filename: string;
  width: number;
  height: number;
  thumbUrl?: string;
  added: Date;
}

class RawPicture implements RawPictureDTO {
  public id: string;
  public userId: string;
  public tourId?: string;
  public url: string;
  public filename: string;
  public width: number;
  public height: number;
  public thumbUrl?: string;
  public added: Date;
  public file?: Blob | File;
  public isDraft = false;
  public isBeingProcessed = false;
  public isMine = false;

  constructor(p: RawPictureDTO) {
    this.id = p.id;
    this.userId = p.userId;
    this.tourId = p.tourId;
    this.url = p.url;
    this.filename = p.filename;
    this.width = p.width;
    this.height = p.height;
    this.thumbUrl = p.thumbUrl;
    this.added = p.added;
  }

  withMeta({
    url,
    thumbUrl,
    width,
    height,
  }: {
    url: string;
    thumbUrl: string;
    width: number;
    height: number;
  }) {
    assert(this.isDraft);
    const p = new RawPicture(this);
    p.url = url;
    p.thumbUrl = thumbUrl;
    p.width = width;
    p.height = height;
    return p;
  }

  static fromJSON(id: string, dict: any): RawPicture {
    const { userId, tourId, url, filename, width, height, thumbUrl, added } =
      dict;
    return new RawPicture({
      id: id,
      userId: userId,
      tourId: tourId,
      url: url,
      filename: filename,
      width: width,
      height: height,
      thumbUrl: thumbUrl,
      added: added,
    });
  }

  static draft = ({
    userId,
    tourId,
    file,
  }: {
    userId: string;
    tourId: string;
    file: Blob | File;
  }) => {
    const filename = uuidv4();
    const p = new RawPicture({
      id: filename,
      userId: userId,
      tourId: tourId,
      url: '',
      filename: filename,
      width: 0,
      height: 0,
      thumbUrl: URL.createObjectURL(file),
      added: new Date(),
    });
    p.file = file;
    p.isDraft = true;
    p.isMine = true;
    return p;
  };

  asMine(): RawPicture {
    const e = new RawPicture(this);
    e.isMine = true;
    return e;
  }

  toJSON(): any {
    return {
      userId: this.userId,
      tourId: this.tourId,
      url: this.url,
      thumbUrl: this.thumbUrl,
      width: this.width,
      height: this.height,
      filename: this.filename,
      added: this.added,
    };
  }

  toPicture = () =>
    new Picture({
      id: this.id,
      userId: this.userId,
      tourId: this.tourId,
      url: this.url,
      thumbUrl: this.thumbUrl,
      comments: [],
      width: this.width,
      height: this.height,
      added: new Date(this.added),
      isBeingProcessed: this.isBeingProcessed,
      isDraft: this.isDraft,
      isMine: this.isMine,
    });
}

export default RawPicture;
