import firebase from 'firebase';
import Vue from 'vue';

class AnalyticsCore {
  logEvent(name: string, props?: { [key: string]: any }): void {
    firebase.analytics().logEvent(name, props);
  }
}

const Analytics = {
  core: new AnalyticsCore(),
  // eslint-disable-next-line no-unused-vars
  install(vue: Vue.VueConstructor, options?: { [key: string]: any }) {
    vue.prototype.$analytics = this.core;
  },
};

export default Analytics;
