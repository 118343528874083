import { resizeImage } from '@/utils/resize-image';
import firebase from 'firebase';

const picture = (userId: string, tourId: string, filename: string) =>
  firebase
    .storage()
    .ref()
    .child('tour-images')
    .child(userId)
    .child(tourId)
    .child(filename);

const thumbnail = (userId: string, tourId: string, filename: string) =>
  picture(userId, tourId, 'thumb_'.concat(filename));

interface UploadParameters {
  userId: string;
  tourId: string;
  filename: string;
  file: File | Blob;
}

export const uploadTourPicture = (p: UploadParameters): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    const task = picture(p.userId, p.tourId, p.filename).put(p.file);
    task.on(firebase.storage.TaskEvent.STATE_CHANGED, null, reject, () =>
      resolve(task.snapshot.ref.getDownloadURL())
    );
  });

export const uploadTourPictureThumbnail = (
  p: UploadParameters
): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    const task = thumbnail(p.userId, p.tourId, p.filename).put(p.file);
    task.on(firebase.storage.TaskEvent.STATE_CHANGED, null, reject, () =>
      resolve(task.snapshot.ref.getDownloadURL())
    );
  });

interface DeleteParameters {
  userId: string;
  tourId: string;
  filename: string;
}

export const deleteTourPicture = async (p: DeleteParameters): Promise<void> =>
  picture(p.userId, p.tourId, p.filename).delete();

export const deleteTourPictureThumbnail = async (
  p: DeleteParameters
): Promise<void> => thumbnail(p.userId, p.tourId, p.filename).delete();
