import { resizeImage } from '@/utils/resize-image';
import firebase from 'firebase';

export enum ImageType {
  profile = 101,
  hero = 102,
}

interface ImageParameters {
  type: ImageType;
  userId: string;
  file?: File | Blob;
  delete?: boolean;
}

const pathFromType = (t: ImageType): string => {
  switch (t) {
    case ImageType.profile:
      return 'profile-image';
    case ImageType.hero:
      return 'hero-image';
  }
};

const fieldFromType = (t: ImageType): string => {
  switch (t) {
    case ImageType.profile:
      return 'profileImageURI';
    case ImageType.hero:
      return 'heroImageURL';
  }
};

const uploadImage = (
  userId: string,
  type: ImageType,
  file: File | Blob
): Promise<string> => {
  function upload(file: File | Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const storage = firebase.storage().ref();
      const uploadTask = storage
        .child('profile-images')
        .child(userId)
        .child(pathFromType(type))
        .put(file);

      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        null,
        reject,
        () => resolve(uploadTask.snapshot.ref.getDownloadURL())
      );
    });
  }

  return resizeImage(file).then((r) => upload(r.blob));
};

const deleteBackwrdCompatible = async (userId: string) => {
  const storage = firebase.storage().ref();
  try {
    const meta: any = await storage
      .child('profile-images')
      .child(userId)
      .getMetadata();

    if (meta && meta.type == 'file') {
      await storage.child('profile-images').child(userId).delete();
    }
  } catch {
    return;
  }
};

const deleteImage = async (
  userId: string,
  type: ImageType
): Promise<string | null> => {
  if (type == ImageType.profile) deleteBackwrdCompatible(userId);

  const storage = firebase.storage().ref();
  return storage
    .child('profile-images')
    .child(userId)
    .child(pathFromType(type))
    .delete()
    .then(() => null);
};

const updateImageURL = (
  userId: string,
  type: ImageType,
  url: string | null
) => {
  const update: any = {};
  update[fieldFromType(type)] = url;
  return firebase
    .firestore()
    .collection('users')
    .doc(userId)
    .set(update, { merge: true });
};

export const processProfileImage = (p: ImageParameters) => {
  let promise: Promise<string | null> | null = null;
  if (p.file) {
    promise = uploadImage(p.userId, p.type, p.file);
  } else if (p.delete) {
    promise = deleteImage(p.userId, p.type);
  }

  if (promise) {
    return promise.then((url) => updateImageURL(p.userId, p.type, url));
  }

  return Promise.resolve();
};
