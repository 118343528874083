import {
  FireNotification,
  sendPushNotification,
} from '@/store/modules/notification';

interface EventNotificationFields {
  title: string;
  body: string;
  eventId: string;
}

class EventNotification implements FireNotification {
  public eventId: string;
  public title: string;
  public body: string;
  public readonly type: string;

  constructor(f: EventNotificationFields) {
    this.eventId = f.eventId;
    this.title = f.title;
    this.body = f.body;
    this.type = 'NOTIFICATION_EVENT';
  }

  toFirestore(): firebase.firestore.DocumentData {
    return {
      title: this.title,
      body: this.body,
      type: this.type,
      eventId: this.eventId,
    };
  }
}

export const notifyEventCreated = ({
  eventId,
  organizerUsername,
}: {
  eventId: string;
  organizerUsername: string;
}) => {
  const notification = new EventNotification({
    eventId: eventId,
    title: 'Neuer Event für dich',
    body: `${organizerUsername} hat gerade einen neuen Event eingestellt.`,
  });
  sendPushNotification('sendNewEventNotification', notification);
};

export const notifyEventUpdated = ({
  eventId,
  eventName,
}: {
  eventId: string;
  eventName: string;
}) => {
  const notification = new EventNotification({
    eventId: eventId,
    title: 'Event aktualisiert',
    body: `Der Event "${eventName}" wurde gerade aktualisiert. Überprüfe was sich verändert hat.`,
  });
  sendPushNotification('sendEventUpdateNotification', notification);
};

export const notifyEventCanceled = ({
  eventId,
  eventName,
}: {
  eventId: string;
  eventName: string;
}) => {
  const notification = new EventNotification({
    eventId: eventId,
    title: 'Event abgesagt',
    body: `Der Event "${eventName}" wurde soeben vom Organisator abgesagt.`,
  });
  sendPushNotification('sendEventUpdateNotification', notification);
};
