import UserNotification from '@/dtos/notification';
import { sendPushNotification } from '@/store/modules/notification';

interface ClubChatNotificationParameters {
  clubId: string;
  clubDisplayName: string;
  senderDisplayName: string;
  text: string;
}

export const notifyNewMessage = (p: ClubChatNotificationParameters) =>
  sendPushNotification(
    'sendClubChatNotification',
    new UserNotification(
      `${p.senderDisplayName} @ ${p.clubDisplayName}`,
      p.text,
      'NOTIFICATION_EVENT',
      p.clubId
    )
  );
