import firebase from 'firebase';
import Place from '@/dtos/place';
import RideeEvent from '@/event/event';
import { containSameElements } from '@/utils/array';
import RideeEventFields from '@/event/event.fields';

class RideeEventUpdate {
  title?: string;
  description?: string;
  startDateTime?: Date;
  endDateTime?: Date;
  location?: Place;
  externalURL?: string;
  tags?: string[];

  static infer(f: RideeEventFields, e: RideeEvent): RideeEventUpdate {
    const u = new RideeEventUpdate();
    if (f.title != e.title) u.title = f.title;
    if (f.description != e.description) u.description = f.description;
    if (f.startDateTime.getTime() != e.startDateTime.getTime())
      u.startDateTime = f.startDateTime;
    if (f.endDateTime?.getTime() != e.endDateTime?.getTime())
      u.endDateTime = f.endDateTime;
    if (f.externalURL != e.externalURL) u.externalURL = f.externalURL;
    if (!f.location.equal(e.location)) u.location = f.location;
    if (!containSameElements(f.tags, e.tags)) u.tags = f.tags;
    return u;
  }

  isNotEmpty(): boolean {
    return Object.keys(this).some(
      (k) => this[k as keyof RideeEventUpdate] != undefined
    );
  }

  toFirestore(): firebase.firestore.DocumentData {
    const d: firebase.firestore.DocumentData = {};
    if (this.title) d.title = this.title;
    if (this.description) d.description = this.description;
    if (this.location) d.location = this.location;
    if (this.startDateTime)
      d.startDateTime = firebase.firestore.Timestamp.fromDate(
        this.startDateTime
      );
    if (this.endDateTime)
      d.endDateTime = firebase.firestore.Timestamp.fromDate(this.endDateTime);
    if (this.externalURL) d.externalURL = this.externalURL;
    if (this.tags) d.tags = this.tags;
    return d;
  }
}

export default RideeEventUpdate;
