import Vue from 'vue';
import App from './App.vue';
import { router } from './router';
import { store } from './store';
import { i18n } from './lang/i18n.config';
import { makeServer } from './api/mock-server';
import firebase from 'firebase';
import VCalendar from 'v-calendar';
import linkify from 'vue-linkify';
import InstantSearch from 'vue-instantsearch';
import VueObserveVisibility from 'vue-observe-visibility';
import VueSimpleAlert from 'vue-simple-alert';
import VModal from 'vue-js-modal';
import '@/assets/css/tailwind.css';
import Analytics from './analytics/analytics';

declare global {
  interface Window {
    Intercom: any;
    MobileApp: any;
  }
}

window.Intercom = window.Intercom || {};
window.MobileApp = window.MobileApp || null;

// Init mock api
if (
  process.env.NODE_ENV === 'development' &&
  process.env.VUE_APP_FAKE_SERVER_START === 'true'
) {
  makeServer();
}

Vue.use(VCalendar, { locale: 'de', firstDayOfWeek: 0 });
Vue.use(InstantSearch);
Vue.use(VueObserveVisibility);
Vue.use(VueSimpleAlert);
Vue.use(Analytics);
Vue.use(VModal, { dynamicDefault: { draggable: false, resizable: false } });

Vue.directive('linkified', linkify);

Vue.config.productionTip = false;

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DB_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};
firebase.initializeApp(firebaseConfig);

const unsubscribe = firebase.auth().onAuthStateChanged((firebaseUser) => {
  new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
    created() {
      if (firebaseUser) {
        store.dispatch('auth/autoSignIn', firebaseUser);
      }
    },
  }).$mount('#app');
  unsubscribe();
});
