import Vue from 'vue';
import VueRouter from 'vue-router';
import { store } from '@/store';
import firebase from 'firebase';
import { eventRoute } from '@/event/event.route';
import { addRoute } from '@/add/add.route';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/mobile',
    component: () =>
      import(
        /* webpackChunkName: "MobileWrapper" */ '../views/Mobile/MobileWrapper.vue'
      ),
    children: [
      {
        path: 'welcome',
        name: 'Mobile Welcome',
        component: () =>
          import(
            /* webpackChunkName: "MobileWrapper" */ '../views/Mobile/Onboarding/Welcome.vue'
          ),
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: '',
        name: 'Mobile App',
        redirect: '/mobile/welcome',
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'discover',
        name: 'Mobile Discover',
        component: () =>
          import(
            /* webpackChunkName: "MobileDashboard" */ '../dashboard/Dashboard.vue'
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'upcoming',
        name: 'Mobile Upcoming',
        component: () =>
          import(
            /* webpackChunkName: "MobileUpcomingTours" */ '../upcoming/Upcoming.vue'
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/tours/archive/',
        name: 'Mobile Tour Archive',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "MobileTourArchive" */ '../tour/TourArchive.vue'
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'clubs',
        name: 'Mobile Clubs',
        component: () =>
          import(/* webpackChunkName: "MobileClubs" */ '../club/Clubs.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'clubs/:clubId/chat',
        name: 'Mobile Club Chat',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "MobileClubChat" */ '../club/chat/ClubChat.vue'
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'saved',
        name: 'Mobile Saved',
        component: () =>
          import(
            /* webpackChunkName: "MobileSaved" */ '../views/Mobile/Saved.vue'
          ),
        meta: {
          requiresAuth: true,
        },
      },
      eventRoute(),
      {
        path: 'tours/:tourId',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "MobileTourDetailWrapper" */ '../router/NavigationWrapper.vue'
          ),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: '',
            name: 'Mobile Tour Detail',
            component: () =>
              import(
                /* webpackChunkName: "MobileTourDetail" */ '../views/Mobile/TourDetail/TourDetail.vue'
              ),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'tourpictures',
            name: 'Mobile Tour Pictures',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "MobileTourPictures" */ '../views/Mobile/TourDetail/TourPictures.vue'
              ),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'members',
            name: 'Mobile Tour Members',
            component: () =>
              import(
                /* webpackChunkName: "MobileTourMembers" */ '../views/Mobile/TourDetail/Members.vue'
              ),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'joined',
            name: 'Mobile Tour Confirmation',
            component: () =>
              import(
                /* webpackChunkName: "MobileTourConfirmation" */ '../views/Mobile/TourDetail/Confirmation.vue'
              ),
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'chat',
            name: 'Mobile Tour Chat',
            component: () =>
              import(
                /* webpackChunkName: "MobileTourChat" */ '../views/Mobile/TourDetail/Chat.vue'
              ),
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'edit',
            name: 'Mobile Edit Tour',
            component: () =>
              import(
                /* webpackChunkName: "MobileEditTour" */ '../views/Mobile/EditTour.vue'
              ),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'duplicate',
            name: 'Mobile Duplicate Tour',
            component: () =>
              import(
                /* webpackChunkName: "MobileDuplicateTour" */ '../views/Mobile/DuplicateTour.vue'
              ),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      addRoute(),
      {
        path: 'users',
        name: 'Mobile Users',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "MobileUserUsers" */ '@/views/Mobile/User/Users.vue'
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'followed',
        name: 'Mobile Followed',
        component: () =>
          import(
            /* webpackChunkName: "MobileFollowed" */ '../views/Mobile/Followed.vue'
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'users/:userId',
        component: () =>
          import(
            /* webpackChunkName: "MobileUsers" */ '../views/Mobile/UsersWrapper.vue'
          ),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: 'profile',
            name: 'Mobile User Profile',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "MobileUserProfile" */ '../views/Mobile/User/Profile.vue'
              ),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: 'profile',
        name: 'Mobile Profile',
        component: () =>
          import(
            /* webpackChunkName: "MobileProfile" */ '../views/Mobile/User/MyProfile.vue'
          ),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "Login" */ '../views/Auth/Login.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () =>
      import(/* webpackChunkName: "Register" */ '../views/Auth/Register.vue'),
  },
  {
    path: '/password-reset',
    name: 'Password Reset',
    component: () =>
      import(
        /* webpackChunkName: "PasswordReset" */ '../views/Auth/PasswordReset.vue'
      ),
  },
  {
    path: '/onboarding/profile',
    name: 'Onboarding Profile',
    component: () =>
      import(
        /* webpackChunkName: "OnboardingProfile" */ '../views/Onboarding/Profile.vue'
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/discover',
    name: 'Discover',
    component: () =>
      import(/* webpackChunkName: "Discover" */ '../views/Discover.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/tours/yours/',
    name: 'Your Tours',
    component: () =>
      import(/* webpackChunkName: "YourTours" */ '../views/YourTours.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/tours/upcoming/',
    name: 'Upcoming Tours',
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "UpcomingTours" */ '../views/UpcomingTours.vue'
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/tours/participated/',
    name: 'Participated Tours',
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "ParticipatedTours" */ '../views/ParticipatedTours.vue'
      ),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/tours/new/',
    name: 'New Tour',
    component: () =>
      import(/* webpackChunkName: "NewTour" */ '../views/NewTour.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/tours/:tourId/edit',
    name: 'Edit Tour',
    component: () =>
      import(/* webpackChunkName: "EditTour" */ '../views/EditTour.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/tours/:tourId/duplicate',
    name: 'Duplicate Tour',
    component: () =>
      import(
        /* webpackChunkName: "DuplicateTour" */ '../views/DuplicateTour.vue'
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/tours/:tourId',
    name: 'Tour Detail',
    component: () =>
      import(/* webpackChunkName: "TourDetail" */ '../views/TourDetail.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // Reset error message when switching to new route
  store.commit('SET_ERROR_MESSAGE', '', { root: true });
  const isAuthenticated = firebase.auth().currentUser;
  if (to.matched.some((route) => route.meta.requiresAuth) && !isAuthenticated) {
    next('/login');
  } else {
    if (
      (to.path.includes('login') ||
        to.path.includes('register') ||
        to.path.includes('password-reset')) &&
      isAuthenticated
    ) {
      next('/');
    } else if (to.path.includes('mobile/welcome') && isAuthenticated) {
      next('/mobile/discover');
    } else {
      next();
    }
  }
});

// Always force to scroll to top of view
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});

export { router };
