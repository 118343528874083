import firebase from 'firebase';
import { Module } from 'vuex';
import { firestoreAction } from 'vuexfire';
import RideeUser from '@/dtos/user';
import { shuffle } from '@/utils/shuffle';

class Users {
  id = Math.random() * 1000;

  _users: RideeUser[] = [];
  get users(): RideeUser[] {
    return this._users;
  }

  private _lastOne: any = null;
  get lastOne(): any {
    return this._lastOne != null ? this._lastOne._doc : null;
  }

  private _loaded = false;
  get loaded(): boolean {
    return this._loaded;
  }

  get pageSize() {
    return 15;
  }

  append(docRefs: any[]) {
    const fetched = docRefs
      .map((docRef) => RideeUser.fromDict(docRef._doc.id, docRef))
      .filter((user) => user.isValid());
    this._users = this._users.concat(fetched);
    this._lastOne = docRefs.length > 0 ? docRefs[docRefs.length - 1] : null;
    this._loaded = docRefs.length < this.pageSize;
  }
}

export interface UserState {
  all: Users;
  active: Users;
  followers: any[];
  following: RideeUser[];
  userProfile: RideeUser | null;
  userFollowing: any[];
  userFollowingCount: number;
  clubs: Users;
}

export const fetchUsersByIds = async (userIds: string[]) => {
  const db = firebase.firestore();
  const userList = [];

  if (userIds) {
    for (const userId of userIds) {
      const memberSnapshot = await db.collection('users').doc(userId).get();
      if (memberSnapshot.exists) {
        userList.push(
          RideeUser.fromDict(memberSnapshot.id, memberSnapshot.data())
        );
      }
    }
    return userList;
  }
};

const docReference = (doc: any) => {
  const data = doc.data();
  Object.defineProperty(data, '_doc', { value: doc });
  return data;
};

const UserModule: Module<UserState, any> = {
  namespaced: true,
  state: {
    all: new Users(),
    allUserBatch: [],
    active: new Users(),
    activeUserBatch: [],
    followers: [],
    following: [],
    userProfile: null,
    userFollowing: [],
    userFollowingCount: 0,
    clubs: new Users(),
  } as UserState,
  getters: {
    getAll: (state) => state.all.users,
    getClubs: (state) => state.clubs.users,
    getActive: (state) => state.active.users,
    getFollowings: (state) => state.following,
    isFollowingUser: (state) => (userId: string) => {
      return state.following.filter((user) => user.id === userId).length > 0;
    },
    getUserProfile: (state) => state.userProfile,
    getUserFollowings: (state) => state.userFollowing,
    getUserFollowingCount: (state) => state.userFollowingCount,
  },
  mutations: {
    SET_FOLLOWERS(state, payload) {
      state.followers = payload;
    },
    SET_FOLLOWINGS(state, payload) {
      state.following = payload;
    },
    SET_USER_FOLLOWINGS(state, payload) {
      state.userFollowing = payload;
    },
    RESET(state) {
      state.followers = [];
      state.following = [];
      state.userProfile = null;
      state.userFollowing = [];
    },
    RESET_PROFILE(state) {
      state.userProfile = null;
    },
    RESET_FOLLOWINGS(state) {
      state.userFollowing = [];
    },
    APPEND_ALL(state, payload) {
      state.all.append(payload);
    },
    APPEND_ACTIVE(state, payload) {
      state.active.append(payload);
    },
    APPEND_CLUBS(state, payload) {
      state.clubs.append(payload);
    },
    RESET_CLUBS(state) {
      state.clubs = new Users();
    },
    SET_USER_FOLLOWINGS_COUNT(state, count) {
      state.userFollowingCount = count;
    },
    RESET_FOLLOWING_COUNT(state) {
      state.userFollowingCount = 0;
    },
  },
  actions: {
    bindAll: firestoreAction(
      async ({ bindFirestoreRef, unbindFirestoreRef, state, commit }) => {
        const users = state.all;

        if (users.loaded) return;

        unbindFirestoreRef('activeUserBatch');

        const query = firebase
          .firestore()
          .collection('users')
          .orderBy('displayName')
          .startAfter(users.lastOne)
          .limit(users.pageSize);
        return bindFirestoreRef('allUserBatch', query, {
          serialize: docReference,
          wait: true,
        }).then((docRefs) => commit('APPEND_ALL', docRefs));
      }
    ),
    bindActive: firestoreAction(
      async ({ bindFirestoreRef, unbindFirestoreRef, state, commit }) => {
        const users = state.active;

        if (users.loaded) return;

        unbindFirestoreRef('allUserBatch');

        let query = firebase
          .firestore()
          .collection('users')
          .where('userStatistics.offeredToursCount', '>', 0)
          .orderBy('userStatistics.offeredToursCount', 'desc')
          .orderBy('displayName')
          .limit(users.pageSize);

        //startAfter(null) doesn't work in case of orderBy 'desc'.
        //https://stackoverflow.com/questions/71082802/vuexfire-firestore-orderby-descending-is-not-working-works-in-acending
        if (users.lastOne != null) {
          query = query.startAfter(users.lastOne);
        }

        return bindFirestoreRef('activeUserBatch', query, {
          serialize: docReference,
          wait: true,
        }).then((docRefs) => commit('APPEND_ACTIVE', docRefs));
      }
    ),
    async fetchClubs({ state, commit }) {
      const clubs = state.clubs;
      if (clubs.loaded) return;
      return firebase
        .firestore()
        .collection('users')
        .where('isClub', '==', true)
        .orderBy('displayName')
        .startAfter(clubs.lastOne)
        .limit(clubs.pageSize)
        .get()
        .then(
          (
            qs: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
          ) => commit('APPEND_CLUBS', qs.docs.map(docReference))
        );
    },
    async clearClubs({ commit }) {
      commit('RESET_CLUBS');
    },
    async fetchActive() {
      const parse = (
        snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
      ) =>
        snapshot.docs
          .map((doc: firebase.firestore.DocumentData) =>
            RideeUser.fromDict(doc.id, doc.data())
          )
          .filter((user: RideeUser) => user.isValid());

      return firebase
        .firestore()
        .collection('users')
        .where('userStatistics.offeredToursCount', '>', 0)
        .orderBy('userStatistics.offeredToursCount', 'desc')
        .limit(35)
        .get()
        .then(parse)
        .then(shuffle)
        .then((usrs) => usrs.slice(0, 10));
    },
    async fetchFollowers({ commit, rootGetters }) {
      const db = firebase.firestore();
      const user = await rootGetters['auth/getUser'];
      const userId = user.data.uid;
      const followerSnapshot = await db
        .collection('followers')
        .doc(userId)
        .collection('users')
        .get();
      const followerIds = followerSnapshot.docs.map((doc) => doc.id);
      const followers = await fetchUsersByIds(followerIds);
      commit('SET_FOLLOWERS', followers);
    },
    async fetchFollowings({ commit, rootGetters }) {
      const db = firebase.firestore();
      const user = await rootGetters['auth/getUser'];
      const userId = user.data.uid;
      const followingSnapshot = await db
        .collection('following')
        .doc(userId)
        .collection('users')
        .get();

      const followingIds = followingSnapshot.docs.map((doc) => doc.id);
      const followings = await fetchUsersByIds(followingIds);
      commit('SET_FOLLOWINGS', followings);
    },
    async fetchUserFollowings({ commit }, userId = '') {
      const db = firebase.firestore();

      const followingSnapshot = await db
        .collection('following')
        .doc(userId)
        .collection('users')
        .get();
      const followingIds = followingSnapshot.docs.map((doc) => doc.id);
      const followings = await fetchUsersByIds(followingIds);
      commit('SET_USER_FOLLOWINGS', followings);
    },
    async fetchUserFollowingCount({ commit }, userId = '') {
      const db = firebase.firestore();
      const followingSnapshot = await db
        .collection('following')
        .doc(userId)
        .collection('users')
        .get();

      const followingCount = followingSnapshot.docs.length;
      commit('SET_USER_FOLLOWINGS_COUNT', followingCount);
    },
    async followUser({ rootGetters, dispatch }, uid: string) {
      const db = firebase.firestore();
      const user = await rootGetters['auth/getUser'];
      const userId = user.data.uid;
      await db
        .collection('following')
        .doc(userId)
        .collection('users')
        .doc(uid)
        .set({ followedAt: new Date() });
      await db
        .collection('followers')
        .doc(uid)
        .collection('users')
        .doc(userId)
        .set({ followedAt: new Date() });
      dispatch('fetchFollowings');
    },
    async removeFollow({ rootGetters, dispatch }, uid: string) {
      const db = firebase.firestore();
      const user = await rootGetters['auth/getUser'];
      const userId = user.data.uid;
      await db
        .collection('following')
        .doc(userId)
        .collection('users')
        .doc(uid)
        .delete();
      await db
        .collection('followers')
        .doc(uid)
        .collection('users')
        .doc(userId)
        .delete();
      dispatch('fetchFollowings');
    },
    async markAsClub({ rootGetters, dispatch }, isClub: boolean) {
      const db = firebase.firestore();
      const user = await rootGetters['auth/getUser'];
      const userId = user.data.uid;
      await db
        .collection('users')
        .doc(userId)
        .set({ isClub: isClub }, { merge: true })
        .then(() => dispatch('auth/updateClubStatus', isClub, { root: true }));
    },
    bindUserProfile: firestoreAction(
      async ({ bindFirestoreRef }, userId: string) => {
        const db = firebase.firestore();
        const userRef = db.collection('users').doc(userId);
        await bindFirestoreRef('userProfile', userRef, {
          serialize: (u) => RideeUser.fromDict(u.id, u.data()),
          wait: true,
        });
      }
    ),
    unbindUserProfile: firestoreAction(async ({ unbindFirestoreRef }) => {
      unbindFirestoreRef('userProfile');
    }),
  },
};

export { UserModule };
