import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from './index';

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: localStorage.getItem('language') ?? 'de',
  fallbackLocale: 'en',
  messages,
});
