import Attendance from './attendance';
import Place from './place';
import RawPlace from './rawplace';

interface TourDTO {
  id: string;
  headerImageURL?: string;
  title: string;
  description: string;
  members: {};
  sportType: string;
  difficultyLevel: string;
  startDateTime: Date;
  location: Place;
  assemblyPoint: string | null;
  distance: number;
  sourceLink: string;
  gpxURL: string | null;
  routeStart: Place | null;
  pace: number;
  altitude: number;
  author: string;
  organizer: string;
  memberCount: number;
  reactionCount: number;
  maxMemberCount: number;
  tags: string[];
  legalDisclaimer: string;
}

class Tour implements TourDTO {
  public id: string;
  public headerImageURL?: string;
  public title: string;
  public description: string;
  public members: {};
  public sportType: string;
  public difficultyLevel: string;
  public startDateTime: Date;
  public location: Place;
  public assemblyPoint: string | null;
  public distance: number;
  public sourceLink: string;
  public gpxURL: string | null;
  public routeStart: Place | null;
  public pace: number;
  public altitude: number;
  public author: string;
  public organizer: string;
  public memberCount: number;
  public reactionCount: number;
  public maxMemberCount: number;
  public tags: string[];
  public legalDisclaimer: string;

  constructor(params: TourDTO) {
    this.id = params.id;
    this.headerImageURL = params.headerImageURL;
    this.title = params.title;
    this.description = params.description;
    this.members = params.members;
    this.sportType = params.sportType;
    this.difficultyLevel = params.difficultyLevel;
    this.startDateTime = params.startDateTime;
    this.location = params.location;
    this.assemblyPoint = params.assemblyPoint;
    this.distance = params.distance;
    this.sourceLink = params.sourceLink;
    this.gpxURL = params.gpxURL;
    this.routeStart = params.routeStart;
    this.pace = params.pace;
    this.altitude = params.altitude;
    this.author = params.author;
    this.organizer = params.organizer;
    this.memberCount = params.memberCount;
    this.maxMemberCount = params.maxMemberCount;
    this.reactionCount = params.reactionCount;
    this.tags = params.tags;
    this.legalDisclaimer = params.legalDisclaimer;
  }

  static fromAlgoliaHit(hit: any): Tour {
    const {
      objectID,
      title,
      author,
      description,
      difficultyLevel,
      distance,
      headerImageURI,
      headerImageURL,
      maxMemberCount,
      organizer,
      reactionCount,
      legalDisclaimer,
      sportType,
      startDateTime,
      pace,
      tags,
      altitude,
      members,
      location,
      startPoint,
    } = hit;
    return new Tour({
      id: objectID,
      headerImageURL: headerImageURL || headerImageURI,
      title: title,
      description: description || '',
      members: members,
      sportType: sportType,
      difficultyLevel: difficultyLevel,
      startDateTime: new Date(startDateTime * 1000),
      location: RawPlace.fromJSON(location || startPoint).toPlace(),
      assemblyPoint: '',
      distance: Number(distance),
      sourceLink: '',
      gpxURL: '',
      routeStart: null,
      pace: parseFloat(pace) + 0.0,
      altitude: parseFloat(altitude) + 0.0,
      author: author,
      organizer: organizer,
      memberCount: members ? members.length : 0,
      reactionCount: reactionCount,
      maxMemberCount: maxMemberCount,
      tags: tags,
      legalDisclaimer: legalDisclaimer,
    });
  }

  static fromDict(id: string, dict: any): Tour {
    const {
      headerImageURI,
      headerImageURL,
      title,
      description,
      members,
      sportType,
      difficultyLevel,
      startDateTime,
      startPoint,
      location,
      assemblyPoint,
      distance,
      sourceLink,
      gpxLink,
      gpxURL,
      routeStart,
      pace,
      altitude,
      author,
      organizer,
      memberCount,
      reactionCount,
      maxMemberCount,
      tags,
      legalDisclaimer,
    } = dict;
    return new Tour({
      id: id,
      headerImageURL: headerImageURL || headerImageURI,
      title: title,
      description: description || '',
      members: members,
      sportType: sportType,
      difficultyLevel: difficultyLevel,
      startDateTime:
        startDateTime instanceof Date ? startDateTime : startDateTime.toDate(),
      location: RawPlace.fromJSON(location || startPoint).toPlace(),
      assemblyPoint: assemblyPoint,
      distance: Number(distance),
      sourceLink: sourceLink || gpxLink,
      gpxURL: gpxURL,
      routeStart: routeStart ? RawPlace.fromJSON(routeStart).toPlace() : null,
      pace: parseFloat(pace) + 0.0,
      altitude: parseFloat(altitude) + 0.0,
      author: author,
      organizer: organizer,
      memberCount: memberCount,
      reactionCount: reactionCount,
      maxMemberCount: maxMemberCount,
      tags: tags,
      legalDisclaimer: legalDisclaimer,
    });
  }

  static fromForm(dict: any): Tour {
    const {
      title,
      description,
      selectedSportType,
      selectedDifficultyLevel,
      startDateTime,
      location,
      assemblyPoint,
      routeStart,
      distance,
      pace,
      altitude,
      sourceLink,
      legalDisclaimer,
    } = dict;
    return new Tour({
      id: '',
      title: title,
      description: description || '',
      members: {},
      sportType: selectedSportType,
      difficultyLevel: selectedDifficultyLevel,
      startDateTime: startDateTime,
      location: location,
      assemblyPoint: assemblyPoint,
      distance: Number(distance),
      sourceLink: sourceLink,
      gpxURL: null,
      routeStart: routeStart,
      pace: parseFloat(pace) + 0.0,
      altitude: parseFloat(altitude) + 0.0,
      author: '',
      organizer: '',
      memberCount: 0,
      reactionCount: 1,
      maxMemberCount: 0,
      tags: [],
      legalDisclaimer: legalDisclaimer,
    });
  }

  toJSON(): { [key: string]: any } {
    const json: { [key: string]: any } = {
      title: this.title,
      description: this.description,
      sportType: this.sportType,
      difficultyLevel: this.difficultyLevel,
      startDateTime: this.startDateTime,
      location: RawPlace.fromPlace(this.location).toJSON(),
      assemblyPoint: this.assemblyPoint,
      distance: this.distance,
      sourceLink: this.sourceLink,
      gpxURL: this.gpxURL,
      routeStart: this.routeStart
        ? RawPlace.fromPlace(this.routeStart).toJSON()
        : null,
      pace: this.pace,
      altitude: this.altitude ?? 0,
      reactionCount: this.reactionCount,
      author: this.author,
      organizer: this.organizer,
      maxMemberCount: this.maxMemberCount ?? 0,
      tags: this.tags,
      legalDisclaimer: this.legalDisclaimer,
    };
    if (this.headerImageURL) json.headerImageURL = this.headerImageURL;
    return json;
  }

  attendance(userId: string) {
    if (this.author === userId) return Attendance.Organizer;
    if (this.members && Object.keys(this.members).length > 0) {
      if (userId in this['members']) return Attendance.Cancel;
      if (this.maxMemberCount > 0) {
        if (Object.keys(this.members).length >= this.maxMemberCount) {
          return Attendance.NotPossible;
        }
      }
    }
    return Attendance.Possible;
  }
}

export default Tour;
