import { FireNotification } from '@/store/modules/notification';
import { firestore } from 'firebase';

interface UserNotificationDTO {
  title: string;
  body: string;
  type: string;
  tourId: string;
}

class UserNotification implements UserNotificationDTO, FireNotification {
  public title: string;
  public body: string;
  public type: string;
  public tourId: string;

  constructor(title: string, body: string, type: string, tourId: string) {
    this.title = title;
    this.body = body;
    this.type = type;
    this.tourId = tourId;
  }

  static fromDict(dict: any): UserNotification {
    const { title, body, type, tourId } = dict;
    return new UserNotification(title, body, type, tourId);
  }

  toFirestore(): firestore.DocumentData {
    return {
      title: this.title,
      body: this.body,
      type: this.type,
      tourId: this.tourId,
    };
  }
}

export default UserNotification;
