import firebase from 'firebase';

interface GPXParameters {
  userId: string;
  tourId: string;
  gpxFile?: Blob | File;
  gpxURL?: string;
  deleteFile?: boolean;
  doNotUpdateTour?: boolean;
}

const gpxBucket = () => {
  const bucketName = 'gs://'.concat(process.env.VUE_APP_FIREBASE_GPX_BUCKET);
  return firebase.app().storage(bucketName).ref();
};

export const loadGPX = async (url: string, stringify: boolean) => {
  const promise = new Promise<Blob>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = () => resolve(xhr.response);
    xhr.onerror = (e) => reject(e);
    xhr.send();
  });

  return stringify ? promise.then((b) => b.text()) : promise;
};

const uploadGPX = async (userId: string, tourId: string, file: Blob | File) => {
  return new Promise<string>(function (resolve, reject) {
    const uploadTask = gpxBucket()
      .child('tour-gpx')
      .child(userId)
      .child(tourId)
      .child('tour.gpx')
      .put(file);
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, null, reject, () =>
      resolve(uploadTask.snapshot.ref.getDownloadURL())
    );
  });
};

const duplicateGPX = async (
  userId: string,
  targetTourId: string,
  sourceURL: string
) => {
  const file = await loadGPX(sourceURL, false);
  return uploadGPX(userId, targetTourId, file as Blob);
};

const deleteGPX = async (userId: string, tourId: string) => {
  return gpxBucket()
    .child('tour-gpx')
    .child(userId)
    .child(tourId)
    .child('tour.gpx')
    .delete()
    .then(() => null);
};

export const processGPX = async (p: GPXParameters) => {
  let promise: Promise<string | null> | null = null;
  if (p.gpxFile) {
    promise = uploadGPX(p.userId, p.tourId, p.gpxFile);
  } else if (p.gpxURL) {
    promise = duplicateGPX(p.userId, p.tourId, p.gpxURL);
  } else if (p.deleteFile) {
    promise = deleteGPX(p.userId, p.tourId);
  }

  return promise?.then((url) => {
    if (p.doNotUpdateTour) return;
    firebase
      .firestore()
      .collection('tours')
      .doc(p.tourId)
      .update({ gpxURL: url });
  });
};
