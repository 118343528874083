import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
import { vuexfireMutations } from 'vuexfire';
import {
  AuthModule,
  ErrorHandlingModule,
  LangModule,
  TourModule,
  UserModule,
  ChatModule,
  TourPicturesModule,
  ClubChatModule,
  HighlightModule,
  RideeEventModule,
  EngagementModule,
} from './modules';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const store = new Vuex.Store({
  modules: {
    auth: AuthModule,
    errorHandling: ErrorHandlingModule,
    lang: LangModule,
    tour: TourModule,
    chat: ChatModule,
    user: UserModule,
    tourpictures: TourPicturesModule,
    clubchat: ClubChatModule,
    highlight: HighlightModule,
    event: RideeEventModule,
    engagement: EngagementModule,
  },
  state: {
    isLoading: false,
    errorMessage: '',
    successMessage: '',
  },
  getters: {
    isLoading: (state) => state.isLoading,
    getErrorMessage: (state) => state.errorMessage,
    getSuccessMessage: (state) => state.successMessage,
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_ERROR_MESSAGE(state, payload) {
      state.errorMessage = payload;
    },
    SET_SUCCESS_MESSAGE(state, payload) {
      state.successMessage = payload;
    },
    RESET(state) {
      state.isLoading = false;
      state.successMessage = '';
    },
    ...vuexfireMutations,
  },
  actions: {
    toggleLoading({ commit }, state: boolean) {
      commit('SET_LOADING', state);
    },
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});

export { store };
