import Place from '@/dtos/place';

interface RideeEventInterface {
  id: string;
  organizerId: string;
  organizerUsername: string;
  headerImageURL?: string;
  title: string;
  description: string;
  startDateTime: Date;
  endDateTime?: Date;
  location: Place;
  externalURL?: string;
  interestCount: number;
  tags: string[];
  legalDisclaimer?: string;
}

class RideeEvent implements RideeEventInterface {
  public id: string;
  public organizerId: string;
  public organizerUsername: string;
  public headerImageURL?: string;
  public title: string;
  public description: string;
  public startDateTime: Date;
  public endDateTime?: Date;
  public location: Place;
  public externalURL?: string;
  public tags: string[];
  public interestCount = 0;
  public legalDisclaimer?: string;

  constructor(p: RideeEventInterface) {
    this.id = p.id;
    this.organizerId = p.organizerId;
    this.organizerUsername = p.organizerUsername;
    this.headerImageURL = p.headerImageURL;
    this.title = p.title;
    this.description = p.description;
    this.interestCount = p.interestCount;
    this.startDateTime = p.startDateTime;
    this.endDateTime = p.endDateTime;
    this.location = p.location;
    this.externalURL = p.externalURL;
    this.tags = p.tags;
    this.legalDisclaimer = p.legalDisclaimer;
  }
}

export default RideeEvent;
