export const aDay = 24 * 60 * 60 * 1000;
export const halfADayAgo = Date.now() - aDay / 2;
export const halfAYearAgo = Date.now() - 182 * aDay;

export const isSameDay = (lhs: Date | number, rhs: Date | number): boolean => {
  lhs = new Date(lhs);
  rhs = new Date(rhs);
  return (
    lhs.getFullYear() == rhs.getFullYear() &&
    lhs.getMonth() == rhs.getMonth() &&
    lhs.getDate() == rhs.getDate()
  );
};

export const isToday = (date: Date | number): boolean =>
  isSameDay(new Date(), date);

export const isTomorrow = (date: Date | number): boolean => {
  const then = new Date(date);
  then.setDate(then.getDate() - 1);
  return isToday(then);
};

export const isThisYear = (date: Date | number): boolean => {
  const then = new Date(date);
  const now = new Date();
  return now.getFullYear() == then.getFullYear();
};

export const isLaterThenWeek = (date: Date | number): boolean => {
  const then = new Date(date);
  const now = new Date();
  const aWeek = 7 * 24 * 60 * 60 * 1000;
  return Math.abs(then.getTime() - now.getTime()) >= aWeek;
};

export const formatRelative = (date: Date | number): string | null => {
  const dateTimeFormat = new Intl.DateTimeFormat('de', {
    hour: 'numeric',
    minute: 'numeric',
  });
  const [{ value: hour }, , { value: minutes }] =
    dateTimeFormat.formatToParts(date);
  if (isToday(date)) {
    return `Heute um ${hour}:${minutes} Uhr`;
  }
  if (isTomorrow(date)) {
    return `Morgen um ${hour}:${minutes} Uhr`;
  }

  return null;
};

export const formatLongDate = (dateRaw: any): string => {
  const rawDate = dateRaw ? dateRaw : Date.now();
  const dateTimeFormat = new Intl.DateTimeFormat('de', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    weekday: 'long',
  });
  const [
    { value: weekday },
    ,
    { value: month },
    ,
    { value: day },
    ,
    { value: year },
    ,
    { value: hour },
    ,
    { value: minutes },
  ] = dateTimeFormat.formatToParts(rawDate);
  return `${weekday} ${month} ${day} ${year} um ${hour}:${minutes} Uhr`;
};

interface DateComponents {
  relative?: string;
  weekday?: string;
  date?: string;
  time: string;
}

export const formatIntoComponents = (
  dateRaw: Date | number,
  relative: boolean
): DateComponents => {
  const rawDate = dateRaw ? dateRaw : Date.now();
  const dateTimeFormat = new Intl.DateTimeFormat('de', {
    month: 'short',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    weekday: 'long',
  });
  const [
    { value: weekday },
    ,
    { value: month },
    ,
    { value: day },
    ,
    { value: hour },
    ,
    { value: minutes },
  ] = dateTimeFormat.formatToParts(rawDate);
  let relativeDay = undefined;
  if (relative) {
    if (isToday(rawDate)) relativeDay = 'Heute';
    else if (isTomorrow(rawDate)) relativeDay = 'Morgen';
  }
  return {
    relative: relativeDay,
    weekday: relativeDay ? undefined : weekday,
    date: relativeDay ? undefined : `${day} ${month}`,
    time: `${hour}:${minutes}`,
  };
};

export const formatShortDate = (dateRaw: any, relative = false): string => {
  const date = dateRaw ? dateRaw : Date.now();
  const dateTimeFormat = new Intl.DateTimeFormat('de', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    weekday: 'short',
  });
  const [
    { value: weekday },
    ,
    { value: month },
    ,
    { value: day },
    ,
    { value: year },
    ,
    { value: hour },
    ,
    { value: minutes },
  ] = dateTimeFormat.formatToParts(date);
  if (relative) {
    const relativeFormat = formatRelative(date);
    if (relativeFormat) return relativeFormat;
  }
  const yearFormat = isThisYear(date) ? `` : `${year}`;
  const timeFormat = isLaterThenWeek(date) ? `` : `, ${hour}:${minutes}`;
  return `${weekday} ${month} ${day} ` + yearFormat + timeFormat;
};

export const roundMinutes = (date: Date) => {
  date.setHours(date.getHours() + Math.round(date.getMinutes() / 60));
  date.setMinutes(0, 0, 0);
  return date;
};

export const tomorrowIfSundayOrNextWeekend = () => {
  const date = new Date();
  const day = date.getDay();
  const leap = day == 6 ? 1 : 6 - day;
  date.setDate(date.getDate() + leap);
  return roundMinutes(date);
};

export const formatDateRange = (
  from: Date | number,
  to: Date | number
): string => {
  const fromComps = formatIntoComponents(from, true);
  const toComps = formatIntoComponents(to, true);
  const fromTime = fromComps.time == '00:00' ? '' : `${fromComps.time} Uhr`;
  const toTime = toComps.time == '00:00' ? '' : `${toComps.time} Uhr`;
  if (isSameDay(from, to)) {
    let timeComponents: string[] = [];
    if (fromTime) timeComponents = timeComponents.concat(['von', fromTime]);
    if (toTime) timeComponents = timeComponents.concat(['bis', toTime]);
    const timeString = timeComponents.length ? timeComponents.join(' ') : '';
    return `${
      fromComps.relative || `${fromComps.weekday} ${fromComps.date}`
    } ${timeString}`;
  }
  return `von ${
    fromComps.relative || `${fromComps.weekday}, ${fromComps.date}`
  } ${fromTime}\nbis ${
    toComps.relative || `${toComps.weekday}, ${toComps.date}`
  } ${toTime}`;
};
