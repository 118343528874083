import firebase from 'firebase';

export enum HighlightTier {
  high,
  medium,
  low,
}

interface RideeHighlightInterface {
  id: string;
  name: string;
  visualUrl: string;
  targetUrl: string;
  tier: HighlightTier;
  starts?: Date;
  expires?: Date;
  isActive: boolean;
}

class RideeHighlight implements RideeHighlightInterface {
  id: string;
  name: string;
  visualUrl: string;
  targetUrl: string;
  tier: HighlightTier;
  starts?: Date;
  expires?: Date;
  isActive: boolean;

  constructor(a: RideeHighlightInterface) {
    this.id = a.id;
    this.name = a.name;
    this.visualUrl = a.visualUrl;
    this.targetUrl = a.targetUrl;
    this.tier = a.tier;
    this.starts = a.starts;
    this.expires = a.expires;
    this.isActive = a.isActive;
  }
}

export default RideeHighlight;
