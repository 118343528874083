import firebase from 'firebase';
import { resizeImage } from '@/utils/resize-image';

interface ImageParameters {
  eventId: string;
  file?: File | Blob;
  oldURL?: string;
  newURL?: string;
  delete?: boolean;
}

const isDefaultImage = (url: string): boolean =>
  decodeURIComponent(url).includes('/default/');

const headerImageReference = (eventId: string): firebase.storage.Reference =>
  firebase.storage().ref().child('event-images').child(eventId).child('header');

const uploadImage = (eventId: string, file: File | Blob): Promise<string> => {
  function upload(file: File | Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const uploadTask = headerImageReference(eventId).put(file);
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        null,
        reject,
        () => resolve(uploadTask.snapshot.ref.getDownloadURL())
      );
    });
  }

  return resizeImage(file).then((r) => upload(r.blob));
};

const deleteImage = async (eventId: string): Promise<void> =>
  headerImageReference(eventId).delete();

const updateImageURL = (eventId: string, url: string) =>
  firebase
    .firestore()
    .collection('events')
    .doc(eventId)
    .update({ headerImageURL: url });

export const processEventHeaderImage = (p: ImageParameters) => {
  let promise: Promise<string | undefined> = Promise.resolve(p.newURL);
  if (p.file) {
    promise = uploadImage(p.eventId, p.file);
  } else if (p.delete && p.oldURL && !isDefaultImage(p.oldURL)) {
    promise = deleteImage(p.eventId).then(() => p.newURL);
  }

  return promise.then((url) => {
    if (url) updateImageURL(p.eventId, url);
  });
};
