import firebase from 'firebase';
import { resizeImage } from '@/utils/resize-image';

interface ImageParameters {
  userId: string;
  tourId: string;
  file?: File | Blob;
  oldURL?: string;
  newURL?: string;
  delete?: boolean;
}

const isDefaultImage = (url: string): boolean =>
  decodeURIComponent(url).includes('/default/');

const imageReference = (
  userId: string,
  tourId: string
): firebase.storage.Reference =>
  firebase
    .storage()
    .ref()
    .child('tour-images')
    .child(userId)
    .child(tourId)
    .child('tour-header-image');

const uploadPicture = (
  userId: string,
  tourId: string,
  file: File | Blob
): Promise<string> => {
  function upload(file: File | Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const uploadTask = imageReference(userId, tourId).put(file);
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        null,
        reject,
        () => resolve(uploadTask.snapshot.ref.getDownloadURL())
      );
    });
  }

  return resizeImage(file).then((r) => upload(r.blob));
};

const deleteImage = async (userId: string, tourId: string): Promise<void> =>
  imageReference(userId, tourId).delete();

const updateURL = (tourId: string, url: string) =>
  firebase
    .firestore()
    .collection('tours')
    .doc(tourId)
    .update({ headerImageURL: url });

export const processTourHeaderImage = (p: ImageParameters) => {
  let promise: Promise<string | undefined> = Promise.resolve(p.newURL);
  if (p.file) {
    promise = uploadPicture(p.userId, p.tourId, p.file);
  } else if (p.delete && p.oldURL && !isDefaultImage(p.oldURL)) {
    promise = deleteImage(p.userId, p.tourId).then(() => p.newURL);
  }

  return promise.then((url) => {
    if (url) updateURL(p.tourId, url);
  });
};
