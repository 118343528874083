import RawPlace from '@/dtos/rawplace';
import firebase from 'firebase';
import RideeEvent from '@/event/event';

interface RawRideeEventInterface {
  id: string;
  organizerId: string;
  organizerUsername: string;
  headerImageURL?: string;
  title: string;
  description: string;
  interestCount: number;
  startDateTime: Date;
  endDateTime?: Date;
  location: RawPlace;
  externalURL?: string;
  tags: string[];
}

class RawRideeEvent implements RawRideeEventInterface {
  public id: string;
  public organizerId: string;
  public organizerUsername: string;
  public headerImageURL?: string;
  public title: string;
  public description: string;
  public startDateTime: Date;
  public endDateTime?: Date;
  public location: RawPlace;
  public externalURL?: string;
  public interestCount = 0;
  public tags: string[];

  constructor(p: RawRideeEventInterface) {
    this.id = p.id;
    this.organizerId = p.organizerId;
    this.organizerUsername = p.organizerUsername;
    this.headerImageURL = p.headerImageURL;
    this.title = p.title;
    this.description = p.description;
    this.externalURL = p.externalURL;
    this.startDateTime = p.startDateTime;
    this.endDateTime = p.endDateTime;
    this.location = p.location;
    this.interestCount = p.interestCount;
    this.tags = p.tags;
  }

  static fromAlgoliaHit(hit: { [key: string]: any }): RawRideeEvent {
    const {
      objectID,
      author,
      organizer,
      title,
      description,
      startDateTime,
      endDateTime,
      location,
      headerImageURL,
      tags,
      interestCount,
    } = hit;
    return new RawRideeEvent({
      id: objectID,
      organizerId: author,
      organizerUsername: organizer,
      title: title,
      description: description,
      headerImageURL: headerImageURL,
      interestCount: interestCount || 0,
      startDateTime: new Date(startDateTime * 1000),
      endDateTime: endDateTime ? new Date(endDateTime * 1000) : undefined,
      location: RawPlace.fromJSON(location),
      tags: tags,
    });
  }

  static fromFirestore(
    id: string,
    data: firebase.firestore.DocumentData
  ): RawRideeEvent {
    const {
      organizerId,
      organizerUsername,
      headerImageURL,
      title,
      description,
      startDateTime,
      endDateTime,
      location,
      externalURL,
      interestCount,
      tags,
    } = data;
    return new RawRideeEvent({
      id: id,
      organizerId: organizerId,
      organizerUsername: organizerUsername,
      headerImageURL: headerImageURL,
      title: title,
      description: description,
      interestCount: interestCount || 0,
      startDateTime: startDateTime.toDate(),
      endDateTime: endDateTime ? endDateTime.toDate() : undefined,
      location: RawPlace.fromJSON(location),
      externalURL: externalURL,
      tags: tags,
    });
  }

  toFirestore(): firebase.firestore.DocumentData {
    const data: firebase.firestore.DocumentData = {
      organizerId: this.organizerId,
      organizerUsername: this.organizerUsername,
      title: this.title,
      description: this.description,
      startDateTime: firebase.firestore.Timestamp.fromDate(this.startDateTime),
      location: this.location.toJSON(),
    };
    if (this.headerImageURL) data.headerImageURL = this.headerImageURL;
    if (this.endDateTime)
      data.endDateTime = firebase.firestore.Timestamp.fromDate(
        this.startDateTime
      );
    if (this.externalURL) data.externalURL = this.externalURL;
    if (this.tags.length > 0) data.tags = this.tags;

    return data;
  }

  toEvent(): RideeEvent {
    return new RideeEvent({
      id: this.id,
      title: this.title,
      description: this.description,
      headerImageURL: this.headerImageURL,
      organizerId: this.organizerId,
      organizerUsername: this.organizerUsername,
      startDateTime: this.startDateTime,
      endDateTime: this.endDateTime,
      location: this.location.toPlace(),
      interestCount: this.interestCount,
      tags: this.tags,
      externalURL: this.externalURL,
    });
  }
}

export default RawRideeEvent;
