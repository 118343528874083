import Place from './place';
import firebase from 'firebase';

interface RawPlaceDTO {
  description: string;
  geoPoint: any;
  lat: number;
  long: number;
}

class RawPlace implements RawPlaceDTO {
  description: string;
  geoPoint: any;
  lat: number;
  long: number;

  constructor(param: RawPlaceDTO) {
    this.description = param.description;
    this.geoPoint = param.geoPoint;
    this.lat = param.lat;
    this.long = param.long;
  }

  static fromPlace(place: Place) {
    const geoPoint = new firebase.firestore.GeoPoint(
      place.latitude,
      place.longitude
    );
    return new RawPlace({
      description: place.description,
      lat: place.latitude,
      long: place.longitude,
      geoPoint: geoPoint,
    });
  }

  toPlace(): Place {
    return new Place(this.lat, this.long, this.description);
  }

  static fromJSON(json: any): RawPlace {
    const { description, geoPoint, lat, long } = json;
    return new RawPlace({
      description: description,
      geoPoint: geoPoint,
      lat: lat,
      long: long,
    });
  }

  toJSON(): any {
    return {
      description: this.description,
      lat: this.lat,
      long: this.long,
      geoPoint: this.geoPoint,
    };
  }
}

export default RawPlace;
