import Place from './place';
import RawPlace from './rawplace';

interface UserStats {
  doneToursCount?: number;
  offeredToursCount?: number;
  offeredEventCount?: number;
}

interface RideeUserDTO {
  id: string;
  username: string;
  bio: string;
  displayName: string;
  email: string | null;
  profileImageURI: string | null;
  heroImageURL?: string;
  hasCompletedOnboarding: boolean | false;
  statistics?: UserStats;
  isClub?: boolean;
  location?: Place;
}

class RideeUser implements RideeUserDTO {
  public id: string;
  public username: string;
  public displayName: string;
  public email: string | null;
  public bio: string;
  public profileImageURI: string | null;
  public heroImageURL?: string;
  public hasCompletedOnboarding: boolean | false;
  public statistics?: UserStats;
  public isClub: boolean;
  public location?: Place;

  constructor(u: RideeUserDTO) {
    this.id = u.id;
    this.username = u.username;
    this.displayName = u.displayName;
    this.email = u.email;
    this.bio = u.bio;
    this.profileImageURI = u.profileImageURI;
    this.heroImageURL = u.heroImageURL;
    this.hasCompletedOnboarding = u.hasCompletedOnboarding;
    this.statistics = u.statistics;
    this.isClub = u.isClub || false;
    this.location = u.location;
  }

  static fromDict(uid: string, dict: any): RideeUser {
    const {
      username,
      displayName,
      email,
      bio,
      profileImageURI,
      heroImageURL,
      location,
      hasCompletedOnboarding,
      userStatistics,
      isClub,
    } = dict;
    return new RideeUser({
      id: uid,
      username: username || '',
      displayName: displayName || '',
      email: email,
      bio: bio || '',
      profileImageURI: profileImageURI,
      heroImageURL,
      hasCompletedOnboarding: hasCompletedOnboarding,
      statistics: userStatistics,
      isClub: isClub,
      location: location ? RawPlace.fromJSON(location).toPlace() : undefined,
    });
  }

  isValid(): boolean {
    const isNotEmptyString = (field: string) =>
      (field as string).trim().length > 0;
    return [this.id, this.username, this.displayName].every(isNotEmptyString);
  }
}

export default RideeUser;
