import firebase from 'firebase';
import { Module } from 'vuex';
import { firestoreAction } from 'vuexfire';
import { shuffle } from '@/utils/shuffle';
import RideeHighlight, { HighlightTier } from '@/highlights/highlight';

const highlight = (
  doc: firebase.firestore.DocumentSnapshot
): RideeHighlight | undefined => {
  const data = doc.data();
  if (!data) return;
  function tier(tierString: string | undefined) {
    switch (tierString) {
      case 'high':
        return HighlightTier.high;
      case 'low':
        return HighlightTier.low;
      default:
        return HighlightTier.medium;
    }
  }

  function tierFromRank(rank: number | undefined) {
    if (!rank) return HighlightTier.medium;
    //0---high---32|33---med---65|66---low---100
    if (rank >= 0 && rank < 33) return HighlightTier.high;
    if (rank >= 66 && rank <= 100) return HighlightTier.low;
    return HighlightTier.medium;
  }

  return new RideeHighlight({
    id: doc.id,
    name: data.name,
    visualUrl: data.visualUrl,
    targetUrl: data.targetUrl,
    tier: data.tier ? tier(data.tier) : tierFromRank(data.rank),
    starts: data.starts ? data.starts.toDate() : undefined,
    expires: data.expires ? data.expires.toDate() : undefined,
    isActive: data.isActive || true,
  });
};

class HighlightState {
  _highlightList: RideeHighlight[] = [];

  get highlightList() {
    return this._highlightList;
  }

  set highlightList(list: RideeHighlight[]) {
    const now = new Date();
    const highTier = new Array<RideeHighlight>();
    const mediumTier = new Array<RideeHighlight>();
    const lowTier = new Array<RideeHighlight>();
    list
      .filter(
        (h) =>
          h.isActive &&
          (!h.starts || h.starts <= now) &&
          (!h.expires || h.expires > now)
      )
      .forEach((h) => {
        switch (h.tier) {
          case HighlightTier.high:
            highTier.push(h);
            break;

          case HighlightTier.low:
            lowTier.push(h);
            break;

          default:
            mediumTier.push(h);
        }
      });
    this._highlightList = [
      ...shuffle(highTier),
      ...shuffle(mediumTier),
      ...shuffle(lowTier),
    ];
  }

  reset() {
    this._highlightList = [];
  }
}

const HighlightModule: Module<HighlightState, any> = {
  namespaced: true,
  state: new HighlightState(),
  getters: {
    all: (state) => state.highlightList,
  },
  mutations: {
    RESET(state) {
      state.reset();
    },
  },
  actions: {
    bind: firestoreAction(async ({ bindFirestoreRef }, tourId) => {
      const db = firebase.firestore();
      const now = firebase.firestore.Timestamp.fromDate(new Date());
      const highlightListRef = db.collection('highlights');
      await bindFirestoreRef('highlightList', highlightListRef, {
        wait: true,
        serialize: highlight,
      });
    }),
  },
};

export { HighlightModule };
