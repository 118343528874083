import UserNotification from '@/dtos/notification';
import { formatShortDate } from '@/utils/date_time';
import firebase from 'firebase';
import { Module } from 'vuex';
import { firestoreAction } from 'vuexfire';
import { sendPushNotification } from './notification';

export interface ChatState {
  messages: any[];
}

const ChatModule: Module<ChatState, any> = {
  namespaced: true,
  state: {
    messages: [],
  } as ChatState,
  getters: {
    getMessages: (state) => state.messages,
  },
  mutations: {
    RESET(state) {
      state.messages = [];
    },
  },
  actions: {
    bindChatMessages: firestoreAction(async ({ bindFirestoreRef }, tourId) => {
      const db = firebase.firestore();
      const chatRef = db.collection('chats').doc(tourId).collection(tourId);
      await bindFirestoreRef('messages', chatRef, {
        wait: true,
        serialize: (doc: any) => {
          const data = doc.data();
          return {
            _id: doc.id,
            content: data.content,
            username: data.userDisplayName,
            timestamp: formatShortDate(Date.parse(data.createdDate.toDate())),
            senderId: data.senderId,
            avatar: data.avatar,
          };
        },
      });
    }),
    async sendMessage(_, { message, tour, userDisplayName }) {
      const db = firebase.firestore();
      const chatRef = db
        .collection('chats')
        .doc(tour.id)
        .collection(tour.id)
        .doc(`${Date.now()}`);
      if (message['content'] != '' && message['senderId'] != null) {
        await db.runTransaction(async () => {
          await chatRef.set(message);
        });

        // Send remote notification to chat members
        const title = `${userDisplayName} @ ${tour.title}`;
        const body = `${message['content']}`;

        const userNotif = new UserNotification(
          title,
          body,
          'NOTIFICATION_EVENT',
          tour.id
        );
        sendPushNotification('sendTourChatNotification', userNotif);
      }
    },
    async updateMessage(_, { tourId, messageId, message }) {
      const db = firebase.firestore();
      const chatRef = db
        .collection('chats')
        .doc(tourId)
        .collection(tourId)
        .doc(messageId.toString());
      chatRef.update(message);
    },
    async deleteMessage(_, { tourId, messageId }) {
      const db = firebase.firestore();
      const chatRef = db
        .collection('chats')
        .doc(tourId)
        .collection(tourId)
        .doc(messageId.toString());
      chatRef.delete();
    },
  },
};

export { ChatModule };
