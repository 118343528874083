class Place {
  public description: string;
  public latitude: number;
  public longitude: number;

  constructor(lat: number, long: number, description: string) {
    this.latitude = lat;
    this.longitude = long;
    this.description = description;
  }

  static void(): Place {
    return new Place(0, 0, '');
  }

  equal(p: Place): boolean {
    return (
      p.description === this.description &&
      p.latitude === this.latitude &&
      p.longitude === this.longitude
    );
  }
}

export default Place;
