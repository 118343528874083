import firebase, { firestore } from 'firebase';
import { Module } from 'vuex';
import { firestoreAction } from 'vuexfire';
import RideeEvent from '@/event/event';
import RawRideeEvent from '@/event/event.raw';
import RideeEventFields from '@/event/event.fields';
import { processEventHeaderImage } from '@/event/event.header.image';
import RideeEventDraft from '@/event/event.draft';
import RideeEventUpdate from './event.update';
import {
  notifyEventCreated,
  notifyEventUpdated,
  notifyEventCanceled,
} from '@/event/event.notification';

class RideeEventState {
  event?: RideeEvent;
  interested = false;

  reset() {
    this.event = undefined;
    this.interested = false;
  }
}

const fireEvents = (): firebase.firestore.CollectionReference =>
  firebase.firestore().collection('events');
const fireEvent = (id: string): firebase.firestore.DocumentReference =>
  fireEvents().doc(id);

const RideeEventModule: Module<RideeEventState, any> = {
  namespaced: true,
  state: new RideeEventState(),
  getters: {
    current: (state) => state.event,
    interested: (state) => state.interested,
  },
  mutations: {
    SET_CURRENT(state, value) {
      state.event = value;
    },
    RESET(state) {
      state.reset();
    },
  },
  actions: {
    bindEvent: firestoreAction(
      async ({ bindFirestoreRef }, eventId: string) => {
        await bindFirestoreRef('event', fireEvent(eventId), {
          wait: true,
          serialize: (doc: firebase.firestore.DocumentData) =>
            RawRideeEvent.fromFirestore(doc.id, doc.data()).toEvent(),
        });
      }
    ),
    async create(
      { rootGetters, dispatch },
      {
        fields,
        image,
        defaultImageURL,
      }: {
        fields: RideeEventFields;
        image?: File;
        defaultImageURL?: string;
      }
    ) {
      const user = await rootGetters['auth/getUser'];

      const draft = new RideeEventDraft(
        user.data.uid,
        user.recordData.username,
        fields
      );
      if (!draft.isValid)
        return Promise.reject('event.store.ts::create::error: invalid draft');

      dispatch('toggleLoading', true, { root: true });
      const db = firebase.firestore();
      const eventId = await db.runTransaction(async () => {
        const event = await fireEvents().add(draft.toFirestore());
        if (image || defaultImageURL) {
          await processEventHeaderImage({
            eventId: event.id,
            file: image,
            newURL: defaultImageURL,
          });
        }
        db.collection('users')
          .doc(user.data.uid)
          .update({
            'userStatistics.offeredEventCount':
              firestore.FieldValue.increment(1),
          });
        return event.id;
      });

      notifyEventCreated({
        eventId: eventId,
        organizerUsername: user.recordData.username,
      });

      dispatch('toggleLoading', false, { root: true });
      return eventId;
    },
    async update(
      { state, dispatch },
      {
        fields,
        image,
        defaultImageURL,
        deleteImage = false,
      }: {
        fields: RideeEventFields;
        image?: File;
        defaultImageURL?: string;
        deleteImage: boolean;
      }
    ) {
      const event = state.event;
      if (!event)
        return Promise.reject('event.store.ts::update::error: no event');

      dispatch('toggleLoading', true, { root: true });

      const update = RideeEventUpdate.infer(fields, event);
      if (update.isNotEmpty())
        await fireEvent(event.id).update(update.toFirestore());

      if (image || defaultImageURL || deleteImage)
        await processEventHeaderImage({
          eventId: event.id,
          file: image,
          oldURL: event.headerImageURL,
          newURL: defaultImageURL,
          delete: deleteImage,
        });

      notifyEventUpdated({ eventId: event.id, eventName: event.title });

      dispatch('toggleLoading', false, { root: true });
      return event.id;
    },
    async delete({ state, rootGetters, dispatch }) {
      const event = state.event;
      if (!event)
        return Promise.reject('event.store.ts::delete::error: no event');
      const user = await rootGetters['auth/getUser'];
      if (user.data.uid != event.organizerId)
        return Promise.reject('event.store.ts::delete::error: not an author');

      dispatch('toggleLoading', true, { root: true });
      const db = firebase.firestore();
      await db.runTransaction(async () => {
        await fireEvent(event.id).delete();
        if (event.headerImageURL)
          processEventHeaderImage({
            eventId: event.id,
            oldURL: event.headerImageURL,
            delete: true,
          });
        db.collection('users')
          .doc(user.data.uid)
          .update({
            'userStatistics.offeredToursCount':
              firestore.FieldValue.increment(-1),
          });
      });

      notifyEventCanceled({ eventId: event.id, eventName: event.title });

      dispatch('toggleLoading', false, { root: true });
    },
    async updateInterest({ state }, { increment }) {
      const event = state.event;
      if (!event)
        return Promise.reject(
          'event.store.ts::incrementInterest::error no event'
        );
      fireEvent(event.id).update({
        interestCount: firebase.firestore.FieldValue.increment(
          increment ? 1 : -1
        ),
      });
    },
  },
};

export { RideeEventModule };
