import { RouteConfig } from 'vue-router';

export const eventRoute = (): RouteConfig => {
  return {
    path: 'event/:eventId',
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "EventNavigationWrapper" */ '../router/NavigationWrapper.vue'
      ),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'Mobile Event Detail',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "MobileEventDetail" */ '../event/EventDetail.vue'
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'edit',
        name: 'Mobile Event Edit',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "MobileEditEvent" */ '../event/EventEditor.vue'
          ),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  };
};
