import firebase from 'firebase';

export interface FireNotification {
  toFirestore(): firebase.firestore.DocumentData;
}

export const sendPushNotification = async (
  cloudFunctioName: string,
  notification: FireNotification,
  receiverUserId?: string,
  wait?: boolean
) => {
  const sendNotification = firebase.functions().httpsCallable(cloudFunctioName);

  const functionBody = { notification: notification.toFirestore() } as any;
  if (receiverUserId) {
    functionBody['receiverUserId'] = receiverUserId;
  }

  if (wait) {
    const res = await sendNotification(functionBody);
  } else {
    sendNotification(functionBody)
      .then((result) => console.debug(result))
      .catch((error) =>
        console.debug('Error while sending Push Notification', error)
      );
  }
};
