interface CommentDTO {
  id: string;
  userId: string;
  text: string;
  added: Date;
}

class Comment implements CommentDTO {
  public id: string;
  public userId: string;
  public text: string;
  public added: Date;

  constructor(p: CommentDTO) {
    this.id = p.id;
    this.userId = p.userId;
    this.text = p.text;
    this.added = p.added;
  }
}

interface PictureDTO {
  id: string;
  userId: string;
  tourId?: string;
  url: string;
  width: number;
  height: number;
  thumbUrl?: string;
  comments?: Comment[];
  added: Date;
  isBeingProcessed: boolean;
  isDraft: boolean;
  isMine: boolean;
}

class Picture implements PictureDTO {
  public readonly id: string;
  public readonly userId: string;
  public readonly tourId?: string;
  public readonly thumbUrl?: string;
  public readonly comments?: Comment[];
  public readonly url: string;
  public readonly width: number;
  public readonly height: number;
  public readonly added: Date;
  public readonly isBeingProcessed: boolean;
  public readonly isDraft: boolean;
  public readonly isMine: boolean;

  constructor(p: PictureDTO) {
    this.id = p.id;
    this.userId = p.userId;
    this.tourId = p.tourId;
    this.url = p.url;
    this.thumbUrl = p.thumbUrl;
    this.comments = p.comments;
    this.added = p.added;
    this.width = p.width;
    this.height = p.height;
    this.isBeingProcessed = p.isBeingProcessed;
    this.isDraft = p.isDraft;
    this.isMine = p.isMine;
  }
}

export default Picture;
