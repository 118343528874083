import { RouteConfig } from 'vue-router';

export const addRoute = (): RouteConfig => {
  return {
    path: 'new',
    name: 'Mobile New',
    props: true,
    component: () => import('../add/AddForm.vue'),
    meta: {
      requiresAuth: true,
    },
  };
};
