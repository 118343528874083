







import Vue from 'vue';
export default Vue.extend({
  name: 'App',
  components: {},
  // created() {
  //   window.addEventListener('resize', this.adaptResizing);
  // },
  // destroyed() {
  //   window.removeEventListener('resize', this.adaptResizing);
  // },
  methods: {
    // adaptResizing() {
    //   console.log('adaptResizing');
    //   if (window.screen.width < 480) {
    //     this.$router.push({
    //       name: 'Mobile Discover',
    //     });
    //     window.Intercom('update', {
    //       /* eslint @typescript-eslint/camelcase: 0 */
    //       hide_default_launcher: true,
    //     });
    //   } else {
    //     if (this.$route.path.includes('mobile')) {
    //       this.$router.push({
    //         name: 'Discover',
    //       });
    //     }
    //   }
    // },
  },
});
