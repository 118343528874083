import firebase, { firestore } from 'firebase';
import { Module } from 'vuex';
import { firestoreAction } from 'vuexfire';
import Tour from '@/dtos/tour';
import Attendance from '@/dtos/attendance';
import { router } from '@/router';
import UserNotification from '@/dtos/notification';
import { sendPushNotification } from './notification';
import { fetchUsersByIds } from './user.store';
import { processGPX } from './gpx';
import { processTourHeaderImage } from '@/tour/tour.header.image';

export interface TourState {
  tours: Tour[];
  tourMembers: any[];
  pastTours: Tour[];
  savedTourIds: string[];
  currentTour: Tour | null;
}

const TourModule: Module<TourState, any> = {
  namespaced: true,
  state: {
    tours: [],
    tourMembers: [],
    pastTours: [],
    savedTourIds: [],
    currentTour: null,
  } as TourState,
  getters: {
    getTours: (state) => state.tours,
    getSavedTours: (state) =>
      state.tours.filter((tour: Tour) => state.savedTourIds.includes(tour.id)),
    getSavedTourIds: (state) => state.savedTourIds,
    getTourMembers: (state) => state.tourMembers,
    getUserCreatedTours: (state) => (uid: string) => {
      return state.tours
        .concat(state.pastTours)
        .filter((tour: Tour) => tour.author === uid);
    },
    getParticipatedTours: (state) => (uid: string) => {
      return state.pastTours.filter(
        (tour: Tour) => tour.members && Object.keys(tour.members).includes(uid)
      );
    },
    getCurrentTour: (state) => state.currentTour,
    getUserFutureTours: (state) => (uid: string) => {
      return state.tours.filter(
        (tour: Tour) =>
          (tour.members && Object.keys(tour.members).includes(uid)) ||
          tour.author === uid
      );
    },
    getUserPastTours: (state) => (uid: string) => {
      return state.pastTours.filter(
        (tour: Tour) =>
          (tour.members && Object.keys(tour.members).includes(uid)) ||
          tour.author === uid
      );
    },
    canMemberJoin: (state) => (userId: string) => {
      const tour = state.currentTour! as Tour;
      if (tour.author === userId) return Attendance.Organizer;
      if (tour.members && Object.keys(tour.members).length > 0) {
        if (userId in tour['members']) return Attendance.Cancel;
        if (tour.maxMemberCount > 0) {
          if (Object.keys(tour.members).length >= tour.maxMemberCount) {
            return Attendance.NotPossible;
          }
        }
      }
      return Attendance.Possible;
    },
  },
  mutations: {
    SET_TOUR_MEMBERS(state, { members }) {
      state.tourMembers = members;
    },
    RESET(state) {
      state.tours = [];
      state.savedTourIds = [];
    },
    RESET_TOUR_MEMBERS(state) {
      state.tourMembers = [];
    },
    SET_SAVED_TOUR_IDS(state, { tourIds }) {
      state.savedTourIds = tourIds;
    },
  },
  actions: {
    async fetchTour({ state }, tourId: string) {
      const db = firebase.firestore();
      const doc = await db.collection('tours').doc(tourId).get();
      return Tour.fromDict(doc.id, doc.data());
    },
    bindToursRef: firestoreAction(async ({ bindFirestoreRef }) => {
      const db = firebase.firestore();
      const now = firebase.firestore.Timestamp.fromDate(new Date());
      const toursRef = db
        .collection('tours')
        .where('startDateTime', '>=', now)
        .orderBy('startDateTime', 'asc');
      await bindFirestoreRef('tours', toursRef, {
        wait: true,
        serialize: (doc: any) => {
          return Tour.fromDict(doc.id, doc.data());
        },
      });
    }),
    bindPastToursRef: firestoreAction(async ({ bindFirestoreRef }) => {
      const db = firebase.firestore();
      const now = firebase.firestore.Timestamp.fromDate(new Date());
      const pastToursRef = db
        .collection('tours')
        .where('startDateTime', '<', now)
        .orderBy('startDateTime', 'desc')
        .limit(200);
      await bindFirestoreRef('pastTours', pastToursRef, {
        wait: true,
        serialize: (doc: any) => {
          return Tour.fromDict(doc.id, doc.data());
        },
      });
    }),
    bindCurrentTour: firestoreAction(
      async ({ bindFirestoreRef }, tourId: string) => {
        const db = firebase.firestore();
        const tourRef = db.collection('tours').doc(tourId);
        await bindFirestoreRef('currentTour', tourRef, {
          wait: true,
          serialize: (doc: any) => {
            return Tour.fromDict(doc.id, doc.data());
          },
        });
      }
    ),
    async fetchUserSavedTourIds({ commit, rootGetters }) {
      const db = firebase.firestore();
      const user = await rootGetters['auth/getUser'];
      const userId = user.data.uid;
      const savedTourIdsSnapshot = await db
        .collection('users')
        .doc(userId)
        .collection('savedTours')
        .get();
      const tourIds = savedTourIdsSnapshot.docs.map((doc) => doc.id);
      commit('SET_SAVED_TOUR_IDS', { tourIds });
    },
    async fetchTourMembers({ commit }, tourId) {
      const db = firebase.firestore();
      const tourSnapshot = await db.collection('tours').doc(tourId).get();
      if (!tourSnapshot.exists) return [];
      const tourDetails: Tour = Tour.fromDict(
        tourSnapshot.id,
        tourSnapshot.data()
      );

      if (!tourDetails) return [];
      const members = tourDetails.members;
      const memberUids = [...Object.keys(members || {}), tourDetails.author];
      const tourMemberList = await fetchUsersByIds(memberUids);
      commit('SET_TOUR_MEMBERS', { members: tourMemberList });
    },
    async createTour(
      { rootGetters, dispatch },
      { tourForm, gpxURL, headerImage, defaultHeaderImageURL }
    ) {
      const db = firebase.firestore();
      const user = await rootGetters['auth/getUser'];
      const tour = Tour.fromForm(tourForm);
      const gpxFile = tourForm.gpx;
      tour.author = user.data.uid;
      tour.organizer = user.recordData.username || '';
      dispatch('toggleLoading', true, { root: true });
      return db.runTransaction(async () => {
        const newTour = await db.collection('tours').add(tour.toJSON());
        if (tour.organizer != '' && tour.organizer != null) {
          await db
            .collection('organizers')
            .doc(tour.organizer)
            .set({ [tour.author]: true }, { merge: true });
        }

        await db.runTransaction(async () => {
          await db
            .collection('users')
            .doc(tour.author)
            .update({
              'userStatistics.offeredToursCount':
                firestore.FieldValue.increment(1),
            });
        });

        // Send tour create remote notification
        const title = 'Neue Tour für dich';
        const body = `${tour.organizer} hat gerade eine neue Tour eingestellt. Melde dich jetzt an.`;

        const userNotif = new UserNotification(
          title,
          body,
          'NOTIFICATION_EVENT',
          tour.id
        );
        sendPushNotification('sendNewTourNotification', userNotif);

        await processGPX({
          userId: tour.author,
          tourId: newTour.id,
          gpxFile: gpxFile,
          gpxURL: gpxURL,
        });

        if (headerImage || defaultHeaderImageURL) {
          await processTourHeaderImage({
            userId: tour.author,
            tourId: newTour.id,
            file: headerImage,
            newURL: defaultHeaderImageURL,
          });
        }
        dispatch('toggleLoading', false, { root: true });
        return newTour.id;
      });
    },
    async updateTour(
      { dispatch },
      { tour, headerImage, gpx, deleteGPX, defaultImageURL }
    ) {
      dispatch('toggleLoading', true, { root: true });
      const db = firebase.firestore();
      await db.collection('tours').doc(tour.id).update(tour.toJSON());

      await processGPX({
        userId: tour.author,
        tourId: tour.id,
        gpxFile: gpx,
        deleteFile: deleteGPX,
      });

      if (headerImage || defaultImageURL) {
        await processTourHeaderImage({
          userId: tour.author,
          tourId: tour.id,
          file: headerImage,
          newURL: defaultImageURL,
        });
      }

      // Send remote notification to participants
      const title = 'Tour aktualisiert';
      const body = `Die Tour "${tour.title}", bei der du teilnimmst, wurde gerade aktualisiert. Überprüfe was sich verändert hat.`;

      const userNotif = new UserNotification(
        title,
        body,
        'NOTIFICATION_EVENT',
        tour.id
      );
      sendPushNotification('sendTourUpdateNotification', userNotif);

      dispatch('toggleLoading', false, { root: true });
      return tour.id;
    },
    async removeTour({ rootGetters, dispatch }, tour) {
      const db = firebase.firestore();
      const user = await rootGetters['auth/getUser'];
      dispatch('toggleLoading', true, { root: true });
      if (tour.id && user.data.uid) {
        // Send remote notification to participants
        const title = 'Tour abgesagt';
        const body = `Die Tour "${tour.title}", bei der du teilnimmst, wurde soeben vom Organisator abgesagt.`;

        const userNotif = new UserNotification(
          title,
          body,
          'NOTIFICATION_EVENT',
          tour.id
        );
        await sendPushNotification(
          'sendTourUpdateNotification',
          userNotif,
          '',
          true
        );

        await db.runTransaction(async () => {
          await db.collection('tours').doc(tour.id).delete();
          await processTourHeaderImage({
            userId: user.data.uid,
            tourId: tour.id,
            delete: true,
          });
          await db.runTransaction(async () => {
            await db
              .collection('users')
              .doc(user.data.uid)
              .update({
                'userStatistics.offeredToursCount':
                  firestore.FieldValue.increment(-1),
              });
          });

          if (tour.gpxURL) {
            await processGPX({
              userId: user.data.uid,
              tourId: tour.id,
              deleteFile: true,
              doNotUpdateTour: true,
            });
          }
        });
      }
      dispatch('toggleLoading', false, { root: true });
    },
    async toggleBookmarkTour({ rootGetters, dispatch }, { tourId, state }) {
      const db = firebase.firestore();
      const user = await rootGetters['auth/getUser'];
      const uid = user.data.uid;

      if (state) {
        await db
          .collection('users')
          .doc(uid)
          .collection('savedTours')
          .doc(tourId)
          .set({ [tourId]: true });
      } else {
        await db
          .collection('users')
          .doc(uid)
          .collection('savedTours')
          .doc(tourId)
          .delete();
      }

      dispatch('fetchUserSavedTourIds');
    },
    async increaseTourReactions(_, tourId: string) {
      const db = firebase.firestore();
      const tourRef = db.collection('tours').doc(tourId);

      db.runTransaction((transaction) => {
        return transaction.get(tourRef).then((doc) => {
          if (doc.exists) {
            const reactionCount = doc?.data()?.reactionCount + 1;
            transaction.update(tourRef, { reactionCount });
          }
        });
      });
    },
    async updateUserDoneTours(_, { userId, value }) {
      const db = firebase.firestore();
      const userRef = db.collection('users').doc(userId);

      await db.runTransaction(async () => {
        await userRef.update({
          'userStatistics.doneToursCount':
            firebase.firestore.FieldValue.increment(value),
        });
      });
    },
    async updateAttendance({ rootGetters, dispatch }, { tour, status }) {
      const db = firebase.firestore();
      const user = await rootGetters['auth/getUser'];
      const userId = user.data.uid;

      let attendanceStatus = false;
      if (status == Attendance.Possible || status == Attendance.Organizer) {
        attendanceStatus = true;
      }

      // Send remote notification to tour organizer
      const title = `Neues Mitglied bei deiner Tour 🎉`;
      const body = `Schaue gleich mal in die ridee App und finde heraus wer deiner Tour "${tour.title}" beigetreten ist.`;

      const userNotif = new UserNotification(
        title,
        body,
        'NOTIFICATION_EVENT',
        tour.id
      );
      sendPushNotification('sendRemoteNotification', userNotif, tour.author);

      if (status == Attendance.Cancel) {
        await db.runTransaction(async () => {
          await db
            .collection('tours')
            .doc(tour.id)
            .update({
              [`members.${userId}`]: firebase.firestore.FieldValue.delete(),
            });
        });
        dispatch('updateUserDoneTours', { userId, value: -1 });
      } else {
        await db.runTransaction(async () => {
          await db
            .collection('tours')
            .doc(tour.id)
            .set(
              {
                members: { [userId]: attendanceStatus },
              },
              { merge: true }
            );
        });
        dispatch('updateUserDoneTours', { userId, value: 1 });
        router.push({
          name: 'Mobile Tour Confirmation',
          params: { tourId: tour.id },
        });
      }
    },
  },
};

export { TourModule };
