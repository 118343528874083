interface ChatMessageInterface {
  id: string;
  text: string;
  senderId: string;
  senderName: string;
  senderProfilePictureURL: string;
  createdDate: Date;
}

class ChatMessage {
  id: string;
  text: string;
  senderId: string;
  senderName: string;
  senderProfilePictureURL: string;
  createdDate: Date;

  constructor(p: ChatMessageInterface) {
    this.id = p.id;
    this.text = p.text;
    this.senderId = p.senderId;
    this.senderName = p.senderName;
    this.senderProfilePictureURL = p.senderProfilePictureURL;
    this.createdDate = p.createdDate;
  }
}

export default ChatMessage;
