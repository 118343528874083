import Place from '@/dtos/place';
import RawPlace from '@/dtos/rawplace';
import firebase from 'firebase';
import RideeEventFields from './event.fields';

class RideeEventDraft {
  organizerId: string;
  organizerUsername: string;
  title: string;
  description: string;
  startDateTime: Date;
  endDateTime?: Date;
  location: Place;
  externalURL?: string;
  tags: string[];

  constructor(
    organizerId: string,
    organizerUsername: string,
    p: RideeEventFields
  ) {
    this.organizerId = organizerId;
    this.organizerUsername = organizerUsername;
    this.title = p.title;
    this.description = p.description;
    this.startDateTime = p.startDateTime;
    this.endDateTime = p.endDateTime;
    this.location = p.location;
    this.externalURL = p.externalURL;
    this.tags = p.tags;
  }

  toFirestore(): firebase.firestore.DocumentData {
    const data: firebase.firestore.DocumentData = {
      organizerId: this.organizerId,
      organizerUsername: this.organizerUsername,
      title: this.title,
      description: this.description,
      startDateTime: firebase.firestore.Timestamp.fromDate(this.startDateTime),
      location: RawPlace.fromPlace(this.location).toJSON(),
    };
    if (this.endDateTime)
      data.endDateTime = firebase.firestore.Timestamp.fromDate(
        this.endDateTime
      );
    if (this.tags.length > 0) data.tags = this.tags;
    if (this.externalURL) data.externalURL = this.externalURL;
    return data;
  }

  get isValid(): boolean {
    const isTrue = (v: boolean) => v;
    return [
      this.organizerId.trim().length > 0,
      this.title.trim().length > 0,
      this.startDateTime > new Date(),
    ].every(isTrue);
  }
}

export default RideeEventDraft;
