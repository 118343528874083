import firebase, { firestore } from 'firebase';
import { Module } from 'vuex';
import { firestoreAction } from 'vuexfire';
import { Engagement, EventEngagement, TourEngagement } from './engagement';

class EventAttendance {
  static none = 'none';
  static interest = 'interest';
}

class EngagementState {
  _userId?: string;
  engagement?: Engagement;

  set userId(value: string) {
    this._userId = value;
  }

  get userId(): string {
    if (!this.isValid()) return '';
    return this._userId!;
  }

  reset() {
    this._userId = undefined;
    this.engagement = undefined;
  }

  isValid(): boolean {
    return this._userId != undefined;
  }

  isEventInteresting(eventId: string): boolean {
    const events = this.engagement?.events;
    if (!events) return false;
    if (!events[eventId]) return false;
    return events[eventId].attendance == EventAttendance.interest;
  }

  likesEvent(eventId: string): boolean {
    const events = this.engagement?.events;
    if (!events) return false;
    return events[eventId].like;
  }
}

const fireEngagements = (): firebase.firestore.CollectionReference =>
  firebase.firestore().collection('engagement');
const fireEngagement = (id: string): firebase.firestore.DocumentReference =>
  fireEngagements().doc(id);

const engagementFromFirestore = (
  doc: firebase.firestore.DocumentData
): Engagement => {
  const r: Engagement = {};
  const d = doc.data();
  if (d.events) {
    const events: { [key: string]: EventEngagement } = {};
    Object.keys(d.events).forEach((k) => {
      const o = d.events[k];
      events[k] = {
        attendance: o.attendance,
        like: o.like || false,
      };
    });
    r.events = events;
  }
  if (d.tours) {
    const tours: { [key: string]: TourEngagement } = {};
    Object.keys(d.tours).forEach((k) => {
      const o = d.tours[k];
      tours[k] = {
        attendance: o.attendance,
        like: o.like || false,
      };
    });
    r.tours = tours;
  }
  return r;
};

const EngagementModule: Module<EngagementState, any> = {
  namespaced: true,
  state: new EngagementState(),
  getters: {
    isEventInetersting: (state) => (eventId: string) =>
      state.isEventInteresting(eventId),
  },
  mutations: {
    setUserId(state, value) {
      state.userId = value;
    },
    setEngagement(state, value) {
      state.engagement = value;
    },
    reset(state) {
      state.reset();
    },
  },
  actions: {
    bindEngagement: firestoreAction(
      async ({ rootGetters, commit, bindFirestoreRef }) => {
        const user = await rootGetters['auth/getUser'];
        if (!user)
          return Promise.reject(
            'activity.store.ts::bindActivity::error: no user'
          );
        commit('setUserId', user.data.uid);
        await bindFirestoreRef('engagement', fireEngagement(user.data.uid), {
          wait: true,
          serialize: engagementFromFirestore,
        });
      }
    ),
    async toggleCurrentEventIneterest({ state, rootGetters, dispatch }) {
      if (!state.isValid())
        return Promise.reject(
          'activity.store.ts::toggleCurrentEventIneterest::error: invalid state'
        );
      const event = await rootGetters['event/current'];
      if (!event)
        return Promise.reject(
          'activity.store.ts::toggleCurrentEventIneterest::error: no event'
        );
      const attendance = state.isEventInteresting(event.id)
        ? EventAttendance.none
        : EventAttendance.interest;
      await fireEngagement(state.userId).set(
        { events: { [event.id]: { attendance: attendance } } },
        { merge: true }
      );
      await dispatch(
        'event/updateInterest',
        { increment: attendance == EventAttendance.interest },
        { root: true }
      );
    },
  },
};

export { EngagementModule };
