import Attendance from '@/dtos/attendance';
import Picture from '@/dtos/picture';
import RawPicture from '@/dtos/rawpicture';
import Tour from '@/dtos/tour';
import { resizeImage } from '@/utils/resize-image';
import firebase from 'firebase';
import { Module } from 'vuex';
import { firestoreAction } from 'vuexfire';
import {
  deleteTourPicture,
  deleteTourPictureThumbnail,
  uploadTourPicture,
  uploadTourPictureThumbnail,
} from './tour.picture';

const pictures = () => firebase.firestore().collection('pictures');

export interface TourPicturesState {
  tour: Tour | undefined;
  pictures: RawPicture[];
  canEdit: boolean;
  canEditAll: boolean;
  readonly maxUploadCount: number;
  readonly uploadedCount: number;
  itemWithId(itemId: string): RawPicture | undefined;
  markBeingProcessed(id: string): void;
  insertDraft(draft: Picture): void;
  removeDraft(draftId: string): void;
}

class AState implements TourPicturesState {
  private readonly kMaxAllowedToUploadCount = 10;
  tour: Tour | undefined;
  pictures = Array<RawPicture>();
  canEdit = false;
  canEditAll = false;

  get maxUploadCount() {
    return this.kMaxAllowedToUploadCount;
  }

  get uploadedCount() {
    return this.pictures.filter((p) => p.isMine).length;
  }

  insertDraft(draft: RawPicture): void {
    this.pictures.unshift(draft);
  }
  itemWithId(draftID: string): RawPicture | undefined {
    return this.pictures.find((p) => {
      return p.id == draftID;
    });
  }
  removeDraft(draftID: string): void {
    const index = this.pictures.findIndex((p) => {
      return p.id == draftID;
    });
    if (index != -1) {
      this.pictures.splice(index, 1);
    }
  }
  markBeingProcessed(itemId: string): void {
    const item = this.itemWithId(itemId);
    if (!item) return;
    item.isBeingProcessed = true;
  }
  reset() {
    this.tour = undefined;
    this.pictures = [];
  }
}

const mineFirstRecentFirst = (lhs: Picture, rhs: Picture): number => {
  const lhsMine = lhs.isMine ? 0 : 1;
  const rhsMine = rhs.isMine ? 0 : 1;
  if (lhsMine != rhsMine) return lhsMine - rhsMine;
  return lhs.added.getTime() - rhs.added.getTime();
};

const TourPicturesModule: Module<TourPicturesState, any> = {
  namespaced: true,
  state: new AState(),
  getters: {
    pictures: (state): Picture[] =>
      state.pictures.map((rp) => rp.toPicture()).sort(mineFirstRecentFirst),
    uploadedCount: (state) => state.uploadedCount,
    maxUploadCount: (state) => state.maxUploadCount,
    canUpload: (state) =>
      state.canEdit && state.uploadedCount < state.maxUploadCount,
    canEdit: (state) => state.canEdit,
    canEditAll: (state) => false,
  },
  mutations: {
    setTour(state, value) {
      state.tour = value;
    },
    insertDraft(state, draft) {
      state.insertDraft(draft);
    },
    removeDraft(state, draftId) {
      state.removeDraft(draftId);
    },
    markBeingProcessed(state, itemId) {
      state.markBeingProcessed(itemId);
    },
    canEdit(state, value) {
      state.canEdit = value;
    },
    canEditAll(state, value) {
      state.canEditAll = value;
    },
    reset(state) {
      state.tour = undefined;
      state.pictures = [];
    },
  },
  actions: {
    async updatePermissions({ commit, rootGetters, state }) {
      if (!state.tour) return;
      const user = await rootGetters['auth/getUser'];
      const myId = user.data.uid;
      const attendance = state.tour.attendance(myId);
      const isOrganizer = attendance === Attendance.Organizer;
      const isParticipant = attendance === Attendance.Cancel;
      commit('canEdit', isOrganizer || isParticipant);
      commit('canEditAll', isOrganizer);
    },
    bindPictures: firestoreAction(
      async ({ rootGetters, dispatch, bindFirestoreRef, commit }, tourId) => {
        const tour = await dispatch('tour/fetchTour', tourId, { root: true });
        commit('setTour', tour);

        const user = await rootGetters['auth/getUser'];
        const myId = user.data.uid;

        const picturesRef = pictures()
          .where('tourId', '==', tourId)
          .orderBy('added', 'desc');

        await bindFirestoreRef('pictures', picturesRef, {
          wait: true,
          serialize: (doc: any) => {
            const p = RawPicture.fromJSON(doc.id, doc.data());
            if (p.userId == myId) return p.asMine();
            return p;
          },
        });
        dispatch('updatePermissions');
      }
    ),
    async addDraft({ state, rootGetters, commit }, file) {
      if (!state.tour)
        return Promise.reject(
          'ERROR: tour.pictures.store.ts::addDraft no tour id'
        );
      const myId = await rootGetters['auth/getUser'].data.uid;
      const draft = RawPicture.draft({
        userId: myId,
        tourId: state.tour.id,
        file: file,
      });
      commit('insertDraft', draft);
    },
    async removeDraft({ state, rootGetters, commit }, draftId) {
      if (!state.tour)
        return Promise.reject(
          'ERROR: tour.pictures.store.ts::removeDraft no tour id'
        );
      commit('removeDraft', draftId);
    },
    async uploadDraft({ state, rootGetters, commit }, draftId) {
      if (!state.tour)
        return Promise.reject(
          'ERROR: tour.pictures.store.ts::uploadDraft no tour id'
        );
      const myId = await rootGetters['auth/getUser'].data.uid;
      const draft = state.itemWithId(draftId);
      if (!draft || !draft.file)
        return Promise.reject(
          'ERROR: tour.pictures.store.ts::uploadDraft no draft with id' +
            draftId +
            ' or it has no file'
        );
      commit('markBeingProcessed', draftId);
      const picture = await resizeImage(draft.file);
      const pictureURL = await uploadTourPicture({
        userId: myId,
        tourId: state.tour.id,
        filename: draft.filename,
        file: picture.blob,
      });
      const thumbnail = await resizeImage(draft.file, 300);
      const thumbnailURL = await uploadTourPictureThumbnail({
        userId: myId,
        tourId: state.tour.id,
        filename: draft.filename,
        file: thumbnail.blob,
      });
      pictures().add(
        draft
          .withMeta({
            url: pictureURL,
            thumbUrl: thumbnailURL,
            width: picture.width,
            height: picture.height,
          })
          .toJSON()
      );
      commit('removeDraft', draft.id);
    },
    async removePicture({ state, rootGetters, commit }, pictureId) {
      if (!state.tour)
        return Promise.reject(
          'ERROR: tour.pictures.store.ts::deletePicture no tour id'
        );
      const myId = await rootGetters['auth/getUser'].data.uid;
      const item = state.itemWithId(pictureId);
      if (!item)
        return Promise.reject(
          'ERROR: tour.pictures.store.ts::deletePicture no picture to delete or it has no filename'
        );
      const filename = item.filename;
      const authorId = item.userId;
      if (authorId != myId && !state.canEditAll)
        return Promise.reject(
          'ERROR: tour.pictures.store.ts::deletePicture no right to delete picture'
        );
      commit('markBeingProcessed', pictureId);
      deleteTourPictureThumbnail({
        userId: authorId,
        tourId: state.tour.id,
        filename: filename,
      }).catch((_) => {
        return;
      });
      return deleteTourPicture({
        userId: authorId,
        tourId: state.tour.id,
        filename: filename,
      }).then(() => pictures().doc(pictureId).delete());
    },
    clear: firestoreAction(async ({ commit, unbindFirestoreRef }) => {
      unbindFirestoreRef('pictures');
      commit('RESET');
    }),
  },
};

export { TourPicturesModule };
