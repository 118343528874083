import { Module } from 'vuex';
import firebase from 'firebase';
import { router } from '@/router';
import Vue from 'vue';
import { ImageType, processProfileImage } from './user.profile.picture';
import Place from '@/dtos/place';
import RawPlace from '@/dtos/rawplace';

const stringFromUnknown = (u: unknown): string => {
  const t = typeof u;
  if (t === 'string') return t;
  if (t === 'object') return t.toString();
  return '';
};

export interface AuthState {
  user: { loggedIn: boolean; data: any; recordData: any };
}

interface UserDetails {
  bio?: string;
  location?: Place;
  displayName?: string;
}

const AuthModule: Module<AuthState, any> = {
  namespaced: true,
  state: {
    user: {
      loggedIn: false,
      data: null,
      recordData: null,
    },
  } as AuthState,
  getters: {
    getUser: (state) => state.user,
    isUserAuthenticated: (state) => state.user.loggedIn,
  },
  mutations: {
    SET_LOGGED_IN(state, payload) {
      state.user.loggedIn = payload;
    },
    SET_USER(state, payload) {
      state.user.data = payload;
    },
    SET_USER_DETAILS(state, payload) {
      Vue.set(state.user, 'recordData', payload);
    },
    SET_CLUB_STATUS(state, isClub) {
      Vue.set(state.user.recordData, 'isClub', isClub);
    },
    RESET(state) {
      state.user = {
        loggedIn: false,
        data: null,
        recordData: null,
      };
    },
  },
  actions: {
    autoSignIn({ commit, dispatch }, user: any) {
      commit('SET_LOGGED_IN', user != null);
      commit('SET_USER', {
        displayName: user.displayName,
        email: user.email,
        uid: user.uid,
        photoURL: user.photoURL,
      });
      window.Intercom('boot', {
        /* eslint @typescript-eslint/camelcase: 0 */
        app_id: 'kceryrnj',
        name: user.displayName, // Full name
        email: user.email,
        created_at: user.metadata.creationTime,
        hide_default_launcher: true,
      });

      // Send userId to mobile wrapper, in order to enable push notifications
      if (window.MobileApp) {
        window.MobileApp.postMessage(`Add token:${user.uid}`);
      } else {
        console.debug('not running inside a Flutter webview');
      }

      dispatch('fetchUserDetails');
    },
    async fetchUserDetails({ commit, state }) {
      const userData = state.user.data;
      if (userData) {
        const db = firebase.firestore();
        const userSnapshot = await db
          .collection('users')
          .doc(userData.uid)
          .get();
        if (userSnapshot.exists) {
          const userDetails = userSnapshot.data();
          if (userDetails) {
            commit('SET_USER_DETAILS', { ...userDetails });
            if (!userDetails['hasCompletedOnboarding']) {
              router.push({ name: 'Onboarding Profile' });
            }
          }
        }
      }
    },
    updateUserDetails({ getters, dispatch, commit }, details: UserDetails) {
      const user = getters['getUser'];
      const userId = user.data.uid;
      if (details.location) {
        details.location = RawPlace.fromPlace(details.location).toJSON();
      }
      firebase
        .firestore()
        .collection('users')
        .doc(userId)
        .set(details, { merge: true })
        .then(() => {
          dispatch('fetchUserDetails');
        })
        .catch((err) => {
          commit('SET_ERROR_MESSAGE', err.message, { root: true });
        });
    },
    signInWithEmail({ commit, dispatch }, { email, password }) {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((data: any) => {
          if (data.user) {
            dispatch('autoSignIn', data.user);
            router.replace({ name: 'Home' });
          } else {
            commit('SET_ERROR_MESSAGE', 'Error. Please get in touch with us', {
              root: true,
            });
          }
        })
        .catch((err) => {
          commit('SET_ERROR_MESSAGE', err.message, { root: true });
        });
    },
    registerUser({ commit, dispatch }, { email, password, name }) {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((data: any) => {
          data.user
            .updateProfile({
              displayName: name,
              photoURL: '',
            })
            .then(() => {
              const db = firebase.firestore();
              const usersRef = db.collection('users');
              usersRef
                .doc(data.user.uid)
                .set({
                  _id: data.user.uid,
                  displayName: data.user.displayName,
                  hasCompletedOnboarding: false,
                  profileImageURI: '',
                  email: data.user.email,
                  username: '',
                  userStatistics: { doneToursCount: 0, offeredToursCount: 0 },
                })
                .then(() => {
                  dispatch('autoSignIn', data.user);
                  router.replace({ name: 'Onboarding Profile' });
                })
                .catch((err) => {
                  commit('SET_ERROR_MESSAGE', err.message, { root: true });
                });
            });
        })
        .catch((err) => {
          commit('SET_ERROR_MESSAGE', err.message, { root: true });
        });
    },
    async updateUserProfile({ commit, dispatch, getters }, { username, file }) {
      const db = firebase.firestore();
      const usersRef = db.collection('usernames').doc(username);
      const docSnapshot = await usersRef.get();
      const user = getters['getUser'];
      const userId = user.data.uid;

      if (docSnapshot.exists) {
        commit(
          'SET_ERROR_MESSAGE',
          'Der gewählte Nutzername ist leider schon vergeben.',
          { root: true }
        );
        return;
      } else {
        usersRef.set({ [userId]: true });
      }

      // Upload profile pic
      if (file) {
        await processProfileImage({
          userId: userId,
          type: ImageType.profile,
          file: file,
        });
      }

      const users = db.collection('users');
      users
        .doc(userId)
        .set(
          {
            hasCompletedOnboarding: true,
            username,
          },
          { merge: true }
        )
        .then(() => {
          dispatch('fetchUserDetails');
          router.replace({ name: 'Discover' });
        })
        .catch((err) => {
          commit('SET_ERROR_MESSAGE', err.message, { root: true });
        });
    },
    async signOut({ getters }) {
      // Send userId to mobile wrapper, in order to disable push notifications
      const user = getters['getUser'];
      const userId = user.data.uid;
      if (window.MobileApp) {
        window.MobileApp.postMessage(`Remove token:${userId}`);
      } else {
        console.debug('not running inside a Flutter webview');
      }
      window.Intercom('shutdown');
      await firebase.auth().signOut();
      router.replace({
        name: 'Login',
      });
    },
    async resetPassword({ commit }, email: string) {
      try {
        await firebase.auth().sendPasswordResetEmail(email);
      } catch (err) {
        commit('SET_ERROR_MESSAGE', stringFromUnknown(err), { root: true });
        return;
      }
      commit('SET_SUCCESS_MESSAGE', 'Passwort erfolgreich zurückgesetzt', {
        root: true,
      });
    },
    updateClubStatus({ commit }, isClub: boolean) {
      commit('SET_CLUB_STATUS', isClub);
    },
    async setDisplayName({ dispatch }, displayName: string) {
      if (displayName.trim().length < 2) return;
      return firebase
        .auth()
        .currentUser?.updateProfile({ displayName: displayName })
        .then(() =>
          dispatch('updateUserDetails', { displayName: displayName })
        );
    },
  },
};

export { AuthModule };
