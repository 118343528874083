const dataURLToBlob = (dataURL: string): Blob => {
  const BASE64_MARKER = ';base64,';
  if (dataURL.indexOf(BASE64_MARKER) == -1) {
    const parts = dataURL.split(','),
      contentType = parts[0].split(':')[1],
      raw = parts[1];
    return new Blob([raw], { type: contentType });
  }

  const parts = dataURL.split(BASE64_MARKER),
    contentType = parts[0].split(':')[1],
    raw = window.atob(parts[1]),
    rawLength = raw.length,
    uInt8Array = new Uint8Array(rawLength);

  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  return new Blob([uInt8Array], { type: contentType });
};

export interface ResizingResult {
  blob: Blob;
  width: number;
  height: number;
}

export const resizeImage = (
  file: Blob | File,
  maxSize = 1280,
  quality = 0.7
): Promise<ResizingResult> => {
  return new Promise(function (resolve, reject) {
    const reader = new FileReader();
    reader.onload = (readerEvent: ProgressEvent<FileReader>) => {
      const image = new Image();
      image.onload = (_) => {
        let width = image.width,
          height = image.height;
        if (Math.max(width, height) <= maxSize) {
          resolve({ blob: file, width: width, height: height });
          return;
        }
        const aspect = width / height;
        if (aspect > 1) {
          width = maxSize;
          height = maxSize / aspect;
        } else {
          width = maxSize * aspect;
          height = maxSize;
        }
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        canvas.getContext('2d')?.drawImage(image, 0, 0, width, height);
        const dataUrl = canvas.toDataURL('image/jpeg', quality);
        const resizedImage = dataURLToBlob(dataUrl);
        resolve({ blob: resizedImage, width: width, height: height });
      };
      if (readerEvent.target?.result == null) {
        reject('resize-image::image not loaded');
        return;
      }
      image.src = readerEvent.target.result as string;
    };
    reader.readAsDataURL(file);
  });
};
