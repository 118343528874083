import firebase from 'firebase';
import { Module } from 'vuex';
import { firestoreAction } from 'vuexfire';
import ChatMessage from '@/chat/chat.message';
import { formatShortDate } from '@/utils/date_time';
import { notifyNewMessage } from './club.chat.notification';

const defaultAvatar =
  'https://firebasestorage.googleapis.com/v0/b/ridee-dev.appspot.com/o/profile-images%2Fridee-logo-round.png?alt=media&token=8ddd6961-99bf-4e68-bf25-31638872228e';

class ClubChatState {
  clubId = '';
  clubDisplayName = '';
  messages: ChatMessage[] = [];

  get isValid() {
    return this.clubId.length > 0;
  }
}

const chat = (
  clubId: string
): firebase.firestore.CollectionReference<firebase.firestore.DocumentData> =>
  firebase.firestore().collection('chats').doc(clubId).collection(clubId);

const message = (
  doc: firebase.firestore.DocumentSnapshot
): ChatMessage | undefined => {
  const data = doc.data();
  if (!data) return undefined;
  return new ChatMessage({
    id: doc.id,
    senderId: data.senderId,
    senderName: data.userDisplayName,
    senderProfilePictureURL: data.avatar || defaultAvatar,
    text: data.content,
    createdDate: data.createdDate.toDate(),
  });
};

const ClubChatModule: Module<ClubChatState, any> = {
  namespaced: true,
  state: new ClubChatState(),
  getters: {
    getMessages: (state) => state.messages,
  },
  mutations: {
    setClubId(state, value) {
      state.clubId = value;
    },
    setClubDisplayName(state, value) {
      state.clubId = value;
    },
    RESET(state) {
      state = new ClubChatState();
    },
  },
  actions: {
    bindMessages: firestoreAction(
      async (
        { commit, bindFirestoreRef },
        { clubId, clubDisplayName }: { clubId: string; clubDisplayName: string }
      ) => {
        commit('setClubId', clubId);
        commit('setClubDisplayName', clubDisplayName);
        await bindFirestoreRef('messages', chat(clubId), {
          wait: true,
          serialize: message,
        });
      }
    ),
    unbindMessages: firestoreAction(async ({ commit, unbindFirestoreRef }) => {
      unbindFirestoreRef('messages');
      commit('RESET');
    }),
    async sendMessage({ rootGetters, state }, text: string) {
      if (!state.isValid)
        return Promise.reject('clubchat.store::sendMessage::invalid state');
      if (text.trim().length == 0)
        return Promise.reject('clubchat.store::sendMessage::empty message');
      const user = await rootGetters['auth/getUser'];
      const draft = {
        content: text,
        createdDate: new Date(),
        senderId: user.data.uid,
        userDisplayName: user.recordData.displayName,
        avatar: user.recordData.profileImageURI || null,
      };

      await chat(state.clubId!).doc(`${Date.now()}`).set(draft);
      notifyNewMessage({
        clubId: state.clubId,
        clubDisplayName: state.clubDisplayName,
        senderDisplayName: draft.userDisplayName,
        text: draft.content,
      });
    },
    async updateMessage(
      { state },
      { messageId, text }: { messageId: string; text: string }
    ) {
      if (!state.isValid)
        return Promise.reject('clubchat.store::updateMessage::invalid state');
      return chat(state.clubId)
        .doc(messageId)
        .update({
          content: text,
          edited: formatShortDate(new Date()),
        });
    },
    async deleteMessage({ state }, messageId: string) {
      if (!state.isValid)
        return Promise.reject('clubchat.store::deleteMessage::invalid state');
      return chat(state.clubId).doc(messageId).delete();
    },
  },
};

export { ClubChatModule };
